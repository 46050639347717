/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-cycle */
import React, { useRef, useState, useEffect } from 'react';
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Sort,
  Filter,
  Resize,
  Edit,
  Toolbar,
  LazyLoadGroup,
  Group,
  Page,
  PdfExport,
  ExcelExport,
  ForeignKey,
} from '@syncfusion/ej2-react-grids';
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import { Query } from '@syncfusion/ej2-data';
import { Header } from '../components';
import armband from '../data/watchsketch.png';
import { invGrid } from '../data/dummy';
import './inventory.css';
import './Zackat.css';

const Inv = () => {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const valuerules = { required: true };
  /*
  const batch = { text: 'Batch', tooltipText: 'Batch Mode Editing', prefixIcon: 'e-expand', id: 'batch' };
  */
  const toolbarOptions = ['Search', 'Add', 'Edit', 'Update', 'Delete', 'PdfExport', 'ExcelExport'];
  const [groups, setGroups] = useState([]);
  const [sortOptions, setSortColumns] = useState({});
  const [reload, setReload] = useState(false);

  let grid = useRef(null);
  let crudUpdate = false;
  let crudCreate = false;
  let crudDelete = false;
  const [data, setData] = useState([]);
  const groupOptions = {
    enableLazyLoading: true,
    columns: groups,
  };

  const [loading, setLoading] = useState(true);
  const orgData = [];
  const usr = JSON.parse(localStorage.getItem('user'));
  const selectedOrg = JSON.parse(localStorage.getItem('organization'));
  useEffect(() => {
    // limit associates to the overall selection
    if (selectedOrg && selectedOrg.id && selectedOrg.id.length > 0) {
      usr.organizationid = selectedOrg.id;
    }
    // fetch data
    const dataFetch = async () => {
      const query = Object.entries(usr)
        .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
        .join('&');
      const url = `${REACT_APP_API_ENDPOINT}/inventory?${query}`;
      const mydata = await (
        await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
        })
      ).json();
      if (mydata.user && mydata.user.preferences && mydata.user.preferences.inventory) {
        if (mydata.user.preferences.inventory.group) {
          setGroups(mydata.user.preferences.inventory.group);
        } else {
          setGroups(['organizationid']);
        }
        if (mydata.user.preferences.inventory.sort && mydata.user.preferences.inventory.sort[0].direction) {
          const mysortedColumns = { columns: mydata.user.preferences.inventory.sort };
          setSortColumns(mysortedColumns);
        }
      } else {
        setGroups(['organizationid']);
      }
      // set state when the data received
      setData(mydata);
      setLoading(false);
    };
    dataFetch();
  }, [reload]);

  if (loading) {
    return <p>Loading... </p>;
  }
  if (data.organization) {
    data.organization.forEach((element) => {
      orgData.push({ id: element._id, value: element.title });
    });
  }

  if (data.role && data.role.inventory && data.role.inventory.permissions.includes('Create')) {
    crudCreate = true;
  }
  if (data.role && data.role.inventory && data.role.inventory.permissions.includes('Update')) {
    crudUpdate = true;
  }
  if (data.role && data.role.inventory && data.role.inventory.permissions.includes('Delete')) {
    crudDelete = true;
  }
  if (data.role && data.role.inventory && !data.role.inventory.permissions.includes('Read')) {
    return <p>Not authorized...</p>;
  }
  const editing = {
    allowEditing: crudUpdate,
    allowAdding: crudCreate,
    allowDeleting: crudDelete,
    showConfirmDialog: false,
    showDeleteConfirmDialog: true,
    allowEditOnDblClick: true,
    mode: 'Dialog',
  };
  function setUserPreferences(pref, val) {
    if (!data.user.preferences) {
      data.user.preferences = {};
    }
    if (!data.user.preferences.inventory) {
      data.user.preferences.inventory = {};
    }
    if (pref === 'group') {
      data.user.preferences.inventory = { group: val, sort: data.user.preferences.inventory.sort };
    } else if (pref === 'sort') {
      data.user.preferences.inventory = { group: data.user.preferences.inventory.group, sort: val };
    }
    // Remove the New Password to avoid creating a new password
    data.user.newPassword = '';
    // Avoid Password comparison
    data.user.password = '';
    fetch(`${REACT_APP_API_ENDPOINT}/user`, {
      method: 'UPDATE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWTToken ${usr.token}`,
      },
      body: JSON.stringify(data.user),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson && responseJson.response && responseJson.response !== 'OK') {
          alert(responseJson.response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const actionBegin = (args) => {
    if (args.requestType === 'ungrouping' || args.requestType === 'grouping') {
      const index = groups.indexOf(args.columnName);
      if (index > -1 && args.requestType === 'ungrouping') { // only splice array when item is found
        groups.splice(index, 1); // 2nd parameter means remove one item only
      } else if (index === -1 && args.requestType === 'grouping') { // only splice array when item is found
        groups.push(args.columnName); // 2nd parameter means remove one item only
      }
      setUserPreferences('group', groups);
    } else if (args.requestType === 'sorting') {
      setUserPreferences('sort', [{ field: args.columnName, direction: args.direction }]);
    }
  };

  const actionComplete = (args) => {
    const editedData = args.data;
    /** Set initial Focus */
    if (args.requestType === 'beginEdit') {
      // eslint-disable-next-line prefer-destructuring
      const dialog = args.dialog;
      dialog.width = '400px';
      dialog.header = `Details of ${args.rowData.device}`;
    }
    if (args.requestType === 'save' && args.form && args.action === 'add') {
      if (editedData && editedData.device.length >= 19 && editedData.device.length <= 20) {
        if (isNaN(args.data.device)) {
          alert(`NaN Invalid Device ID ${editedData.device}`);
          args.cancel = true;
          setReload(!reload);
          return;
        }

        // Here you can send the updated data to your server using AJAX call

        editedData.simstatus = { status: 'active' };
        const result = orgData.find(({ id }) => id === editedData.organizationid);
        if (!result) {
          alert('Error finding Organization');
          args.cancel = true;
          setReload(!reload);
          return;
        }
        editedData.organization = result.value;
        fetch(`${REACT_APP_API_ENDPOINT}/inventory`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(editedData),
        })
          .then((response) => response.json())
          .then((responseJson) => {
            // The default edit operation is cancelled
            console.log('inventory post', responseJson);
            if (responseJson && responseJson.response && responseJson.response !== 'OK') {
              alert(responseJson.response);
              setReload(!reload);
              args.cancel = true;
            }
            // The added/edited data will be saved in the Grid
            if (responseJson && responseJson._id) {
              args.data._id = responseJson._id;
            }
          })
          .catch((error) => {
            console.log('got an error on inventory post');
            setReload(!reload);
            args.cancel = true;
          });
      } else {
        alert(`Invalid device ID ${args.data.device}`);
        setReload(!reload);
      }
    } else if (args.requestType === 'save' && args.form && args.action === 'edit') {
      if (args.data) {
        const invs = [];
        invs.push(editedData);
        // Here you can send the updated data to your server using AJAX call
        fetch(`${REACT_APP_API_ENDPOINT}/inventory`, {
          method: 'UPDATE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(invs),
        })
          .then((response) => response.json())
          .then((responseJson) => {
            // The default edit operation is cancelled
            if (responseJson && responseJson.response && responseJson.response !== 'OK') {
              alert(responseJson.response);
              args.cancel = true;
            }
            // The added/edited data will be saved in the Grid
          })
          .catch((error) => {
            console.log(error);
            args.cancel = true;
          });
      }
    } else if (args.requestType === 'batchsave') {
      if (args.rows && args.rows.length > 0) {
        const invs = [];
        for (let x = 0; x < args.rows.length; x++) {
          invs.push(args.rows[x].data);
        }
        // The default edit operation is cancelled

        // Here you can send the updated data to your server using AJAX call
        fetch(`${REACT_APP_API_ENDPOINT}/inventory`, {
          method: 'UPDATE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(invs),
        })
          .then((response) => response.json())
          .then((responseJson) => {
            // The added/edited data will be saved in the Grid
            if (responseJson && responseJson.response && responseJson.response !== 'OK') {
              alert(responseJson.response);
              args.cancel = true;
              setReload(!reload);
            }
          })
          .catch((error) => {
            console.log(error);
            args.cancel = true;
            setReload(!reload);
          });
      }
    } else if (args.requestType === 'delete') {
      let entry = ' this entry?';
      if (args.data.length > 1) {
        entry = ' these entries?';
      }
      if (args.data) {
        // Here you can send the updated data to your server using AJAX call
        fetch(`${REACT_APP_API_ENDPOINT}/inventory`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(editedData),
        })
          .then((response) => response.json())
          .then((responseJson) => {
            // The added/edited data will be saved in the Grid
            // The default edit operation is cancelled
            if (responseJson && responseJson.response && responseJson.response !== 'OK') {
              alert(responseJson.response);
              args.cancel = true;
            }
          })
          .catch((error) => {
            console.log(error);
            args.cancel = true;
            setReload(!reload);
          });
      }
    }
  };

  function onFileUpload(args) {
    // add addition data as key-value pair.
    console.log('onFileUpload', args);
    args.customFormData = [{ organizationid: data.user.organizationid }];
  }
  let uploadObj;
  const path = {
    removeUrl: `${REACT_APP_API_ENDPOINT}/inventoryremove`,
    saveUrl: `${REACT_APP_API_ENDPOINT}/inventoryupload`,
  };

  const FilterOptions = {
    type: 'Menu',
  };
  function completeUpload(args) {
    console.log('completeUpload', args);
  }
  const buttons = { browse: 'Choose File', clear: 'Clear All', upload: 'Upload All' };
  const currentThemeColor = localStorage.getItem('colorMode');
  const clickHandler = (args) => {
    if (grid && args.item.properties.text === 'PDF Export') {
      const properties = {
        fileName: `PDFExport_Inventory_${new Date().toISOString()}.pdf`,
        pageOrientation: 'Landscape',
      };
      grid.pdfExport(properties);
    } else if (grid && args.item.properties.text === 'Excel Export') {
      const properties = {
        fileName: `ExcelExport_Inventory_${new Date().toISOString()}.xlsx`,
      };
      grid.excelExport(properties);
    } else if (grid && args.item.properties.text === 'Batch') {
      if (grid.properties.editSettings.properties.mode === 'Dialog') {
        grid.properties.editSettings.properties.mode = 'Batch';
      } else {
        grid.properties.editSettings.properties.mode = 'Dialog';
      }
    }
  };
  const orgRule = { required: true };
  const organizations = {
    params: {
      actionComplete: () => false,
      allowFiltering: true,
      dataSource: data.organization,
      fields: { value: '_id', text: 'title' },
      query: new Query(),
    },
  };
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl zackat-div">
      <img
        className="w-20 h-20"
        src={armband}
        alt="arm band"
      />
      <Header title="Inventory" />
      <div style={{
        backgroundColor: currentThemeColor,
        textAlign: 'right',
        marginTop: '-40px',
      }}
      >
        <div id="droparea">{crudCreate ? 'IMPORT' : ''}
          {crudCreate === true && (
            <UploaderComponent
              id="fileupload"
              type="file"
              ref={(uplaod) => { uploadObj = uplaod; }}
              asyncSettings={path}
              buttons={buttons}
              multiple={false}
              actionComplete={completeUpload}
              autoUpload={false}
              uploading={onFileUpload}
              allowedExtensions=".csv"
            />
          )}
        </div>
        <GridComponent
          dataSource={data.inventory}
          enableHover
          allowPaging
          pageSettings={{ pageCount: 5 }}
          toolbar={toolbarOptions}
          editSettings={editing}
          allowSorting
          allowResizing
          allowFiltering={data && data.inventory && data.inventory.length > 0}
          filterSettings={FilterOptions}
          actionComplete={actionComplete}
          actionBegin={actionBegin}
          toolbarClick={clickHandler}
          allowTextWrap
          allowPdfExport
          allowExcelExport
          allowGrouping
          groupSettings={groupOptions}
          sortSettings={sortOptions}
          ref={(g) => { grid = g; }}
        >
          <ColumnsDirective>
            <ColumnDirective type="checkbox" width="50" />
            <ColumnDirective
              field="device"
              headerText="Device"
              width="80"
              textAlign="Center"
              validationRules={valuerules}
              visible
            />
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {invGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}

            <ColumnDirective
              field="organizationid"
              headerText="Organization"
              width="70"
              textAlign="Center"
              editType="dropdownedit"
              edit={organizations}
              validationRules={valuerules}
              visible
              foreignKeyField="_id"
              foreignKeyValue="title"
              dataSource={organizations.params.dataSource}
            />

          </ColumnsDirective>
          <Inject services={[Search, Page, Edit, Toolbar, Filter, Sort, Resize, PdfExport, ExcelExport, ForeignKey, LazyLoadGroup, Group]} />
        </GridComponent>
      </div>
    </div>
  );
};
export default Inv;
