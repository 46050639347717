/* eslint-disable no-plusplus */
/* eslint-disable import/no-cycle */
import React from 'react';
import { DashboardLayoutComponent } from '@syncfusion/ej2-react-layouts';
import { GoDotFill } from 'react-icons/go';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Edit,
  Toolbar,
  Resize,
  Sort,
  Filter,
  ColumnChooser,
  Inject,
  Search,
} from '@syncfusion/ej2-react-grids';
// import {
//  AccumulationChartComponent,
//  AccumulationSeriesCollectionDirective,
//  AccumulationSeriesDirective,
//  AccumulationLegend,
//  PieSeries,
//  AccumulationDataLabel,
//  Inject,
//  AccumulationTooltip,
// } from '@syncfusion/ej2-react-charts';

import { Stacked, Button, SparkLine, ChartsHeader, Pie as PieChart } from '../components';
import { pieChartData } from '../data/dummy';

import { Methods } from './Methods';
import { useStateContext } from '../contexts/ContextProvider';
import './Zackat.css';

const Dashboard = () => {
  const { loading, data } = Methods('dashboard', 'GET', '');
  const { currentColor, currentMode } = useStateContext();
  const cellSpacing = [5, 5];

  if (loading) {
    return <p>Loading... </p>;
  }
  let activeCount = 0;
  let stockCount = 0;
  let retiredCount = 0;
  const inventoryAreaData = [];
  for (let ix = 0; ix < data[0].length; ix++) {
    if (data[0][ix]._id.status === 'stock') {
      stockCount += data[0][ix].count;
    } else if (data[0][ix]._id.status === 'retired') {
      retiredCount += data[0][ix].count;
    } else if (data[0][ix]._id.status === 'active') {
      activeCount += data[0][ix].count;
    }
    inventoryAreaData.push({ x: ix, yval: data[0][ix].count });
  }
  const totalCount = activeCount + retiredCount + stockCount;
  let jactive = 0;
  let jinactive = 0;
  let jcompleted = 0;
  let junscheduled = 0;

  const jobAreaData = [];
  for (let ix = 0; ix < data[1].length; ix++) {
    if (data[1][ix]._id.status === 'active') {
      jactive += data[1][ix].count;
    } else if (data[1][ix]._id.status === 'inactive') {
      jinactive += data[1][ix].count;
    } else if (data[1][ix]._id.status === 'unscheduled') {
      junscheduled += data[1][ix].count;
    } else if (data[1][ix]._id.status === 'completed') {
      jcompleted += data[1][ix].count;
    }
    jobAreaData.push({ x: ix, y: data[1][ix].count, text: data[1][ix]._id.status });
  }
  const jCount = jactive + junscheduled + jcompleted + jinactive;

  const pingData = [];
  for (let ix = 0; ix < data[2].length; ix++) {
    pingData.push({ x: data[2][ix]._id.day, y: data[2][ix].count, text: data[2][ix]._id.title ? data[2][ix]._id.title : 'missing' });
  }
  console.log(pingData, jCount, data[2]);
  return (
    <div>
      <div className="control-section">
        <DashboardLayoutComponent id="default_dashboard" cellSpacing={cellSpacing} allowResizing columns={3}>
          <div id="one" className="e-panel" data-row="0" data-col="0" data-sizex="1" data-sizey="1">
            <span id="close" className="e-template-icon e-clear-icon" />
            <div className="e-panel-container">
              <ChartsHeader width="full" category="" title="Job Pings" />
              <GridComponent
                dataSource={pingData}
                enableHover
                allowPaging
                pageSettings={{ pageSize: 5, pageCount: 5 }}
                editSettings={false}
                allowSorting
                allowResizing
              >
                <ColumnsDirective>
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  {/* rolesGrid.map((item, index) => <ColumnDirective key={index} {...item} />) */}
                  <ColumnDirective field="x" headerText="Day" textAlign="Left" isPrimaryKey width="80" />
                  <ColumnDirective field="y" headerText="Count" textAlign="Left" width="120" />
                  <ColumnDirective field="text" headerText="Titile" textAlign="Left" width="120" />
                </ColumnsDirective>
                <Inject services={[Search, Page, Selection, Toolbar, Edit, Sort, Filter, ColumnChooser, Resize]} />
              </GridComponent>
            </div>
          </div>
          <div id="three" className="e-panel" data-row="0" data-col="1" data-sizex="2" data-sizey="1">
            <span id="close" className="e-template-icon e-clear-icon" />
            <div className="e-panel-container">
              <ChartsHeader className="flex" category="Pie" title="Job Metrics" />
              <PieChart id="chart-pie" data={pieChartData} legendVisiblity height="80%" />
            </div>
          </div>
          {/*
          <div id="two" className="e-panel" data-row="0" data-col="2" data-sizex="1" data-sizey="1">
            <span id="close" className="e-template-icon e-clear-icon" />
            <div className="e-panel-container">
              //
              <AccumulationChartComponent
                id="metricpie"
                legendSettings={{ visible: true, background: 'white' }}
                background={currentMode === 'Dark' ? '#33373E' : '#fff'}
                tooltip={{ enable: true }}
              >
                <Inject services={[AccumulationLegend, PieSeries, AccumulationDataLabel, AccumulationTooltip]} />
                <AccumulationSeriesCollectionDirective>
                  <AccumulationSeriesDirective
                    name="Job Metrics"
                    dataSource={jobAreaData}
                    xName="x"
                    yName="y"
                    legendVisiblity
                    innerRadius="40%"
                    startAngle={0}
                    endAngle={360}
                    radius="70%"
                    explode
                    explodeOffset="10%"
                    explodeIndex={2}
                    dataLabel={{
                      visible: true,
                      name: 'text',
                      position: 'Inside',
                      font: {
                        fontWeight: '600',
                        color: '#fff',
                      },
                    }}
                  />
                </AccumulationSeriesCollectionDirective>
              </AccumulationChartComponent>

          <div className="text-align">2</div>
      </div>
    </div>
    */}
          {/*
          <div id="four" className="e-panel" data-row="1" data-col="0" data-sizex="1" data-sizey="1">
            <span id="close" className="e-template-icon e-clear-icon" />
            <div className="e-panel-container">
              <div className="text-align">4</div>
            </div>
          </div>
          <div id="five" className="e-panel" data-row="1" data-col="1" data-sizex="1" data-sizey="1">
            <span id="close" className="e-template-icon e-clear-icon" />
            <div className="e-panel-container">
              <div className="text-align">5</div>
            </div>
          </div>
          <div id="eight" className="e-panel" data-row="1" data-col="2" data-sizex="1" data-sizey="1">
            <span id="close" className="e-template-icon e-clear-icon" />
            <div className="e-panel-container">
              <div className="text-align">6</div>
            </div>
          </div>
          */}
        </DashboardLayoutComponent>
      </div>
      <div className="flex gap-10 flex-wrap justify-center">
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg m-3 p-4 rounded-2xl md:w-780  ">
          <div className="flex justify-between">
            <p className="font-semibold text-xl">Inventory</p>
            <div className="flex items-center gap-4">
              <p className="flex items-center gap-2 text-gray-600 hover:drop-shadow-xl">
                <span>
                  <GoDotFill />
                </span>
                <span>Total</span>
              </p>
              <p className="flex items-center gap-2 text-green-400 hover:drop-shadow-xl">
                <span>
                  <GoDotFill />
                </span>
                <span>Active</span>
              </p>
            </div>
          </div>
          <div className="mt-10 flex gap-10 flex-wrap justify-center">
            <div className=" border-r-1 border-color m-4 pr-10">
              <div>
                <p>
                  <span className="text-3xl font-semibold">{totalCount}</span>
                  <span
                    className="p-1.5 hover:drop-shadow-xl cursor-pointer rounded-full text-white bg-green-400 ml-3 text-xs"
                  >
                    23%
                  </span>
                </p>
                <p className="text-gray-500 mt-1">Total</p>
              </div>
              <div className="mt-8">
                <p className="text-3xl font-semibold">{activeCount}</p>

                <p className="text-gray-500 mt-1">Active</p>
              </div>

              <div className="mt-5">
                <SparkLine
                  currentColor={currentColor}
                  id="line-sparkLine"
                  type="Line"
                  height="80px"
                  width="250px"
                  data={inventoryAreaData}
                  color="red"
                />
              </div>
              <div className="mt-10">
                <Button
                  color="white"
                  bgColor={currentColor}
                  text="Download Report ↵"
                  borderRadius="10px"
                />
              </div>
            </div>
            <div>
              <Stacked currentMode={currentMode} width="320px" height="360px" />
            </div>
          </div>
        </div>
        <div>
          <div
            className=" rounded-2xl md:w-400 p-4 m-3"
            style={{ backgroundColor: currentColor }}
          >
            <div className="flex justify-between items-center ">
              <p className="font-semibold text-white text-2xl">Inventory</p>

              <div>
                <p className="text-2xl text-white font-semibold mt-8">{totalCount}</p>
                <p className="text-gray-200">Totals</p>
              </div>
            </div>

            <div className="mt-4">
              <SparkLine
                currentColor={currentColor}
                id="column-sparkLine"
                height="100px"
                type="Column"
                data={inventoryAreaData}
                width="320"
                color="rgb(242, 252, 253)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
