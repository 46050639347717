/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-cycle */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useRef, useEffect } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  Edit,
  Toolbar,
  Resize,
  Sort,
  Filter,
  Search,
  ForeignKey,
  LazyLoadGroup,
  Group,
} from '@syncfusion/ej2-react-grids';
// ColumnChooser,
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import { Query } from '@syncfusion/ej2-data';
import { Header } from '../components';
import profile from '../data/profile.png';

import './Zackat.css';

const Users = () => {
  // 'ColumnChooser'
  const [enableDetails, setDetails] = useState(true);
  const invite = { text: 'Invite', tooltipText: 'Resend Invitation', prefixIcon: 'e-resize', id: 'invite', disabled: enableDetails };
  const toolbarOptions = ['Add', 'Edit', 'Delete', 'Search', invite];
  const { REACT_APP_API_ENDPOINT } = process.env;
  const valuerules = { required: true };
  let grid = useRef(null);
  const [reload, setReload] = useState(true);
  const usr = JSON.parse(localStorage.getItem('user'));
  const selectedOrg = JSON.parse(localStorage.getItem('organization'));
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [groups, setGroups] = useState([]);
  const [sortOptions, setSortColumns] = useState({});

  useEffect(() => {
    // limit associates to the overall selection
    if (selectedOrg && selectedOrg.id && selectedOrg.id.length > 0) {
      usr.organizationid = selectedOrg.id;
    }
    // fetch data
    const dataFetch = async () => {
      const query = Object.entries(usr)
        .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
        .join('&');
      const url = `${REACT_APP_API_ENDPOINT}/users?${query}`;
      const mydata = await (
        await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
        })
      ).json();
      if (mydata.user && mydata.user.preferences && mydata.user.preferences.users) {
        if (mydata.user.preferences.users.group) {
          setGroups(mydata.user.preferences.users.group);
        } else {
          setGroups(['organizationid']);
        }
        if (mydata.user.preferences.users.sort && mydata.user.preferences.users.sort[0].direction) {
          const mysortedColumns = { columns: mydata.user.preferences.users.sort };
          setSortColumns(mysortedColumns);
        }
      } else {
        setGroups(['organizationid']);
      }
      // set state when the data received
      setData(mydata);
      setLoading(false);
    };
    dataFetch();
  }, []);
  const groupOptions = {
    enableLazyLoading: true,
    columns: groups,
  };
  function handleChange(e) {
    let upData = null;
    for (let idx = 0; idx < data.users.length; idx += 1) {
      if (data.users[idx]._id === e.target.id) {
        upData = data.users[idx];
        break;
      }
    }
    const filedata = new FormData();
    const imagedata = e.target.files[0];
    filedata.append('userfile', imagedata);
    if (upData !== null) {
      filedata.append('_id', upData._id);
      filedata.append('fileName', e.target.files[0].name);
    } else {
      return;
    }
    fetch(`${REACT_APP_API_ENDPOINT}/ufileup`, {
      method: 'POST',
      headers: {
        Authorization: `JWTToken ${usr.token}`,
      },
      body: filedata,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // window.location.reload();
        setReload(!reload);
      })
      .catch((error) => {
        alert('An error has occured during Insert!');
        console.log(error);
      });
  }
  const clickHandler = (args) => {
    if (grid && args.item.properties.text === 'Invite') {
      const selectedRecords = grid.getSelectedRecords();
      if (!selectedRecords || selectedRecords.length === 0) {
        alert('Please select a user');
      } else {
        const recipient = prompt('Please enter the email address on file of the User that you would like to receive the original invitation!', selectedRecords[0].email);
        if (recipient) {
          fetch(`${REACT_APP_API_ENDPOINT}/reinvite?recipient=${recipient}&email=${usr.email}`, {
            method: 'GET',
            headers: {
              Authorization: `JWTToken ${usr.token}`,
            },
          })
            .then((response) => response.json())
            .then((responseJson) => {
              if (responseJson && responseJson.response && responseJson.response !== 'OK') {
                alert(responseJson.response);
              }
            })
            .catch((error) => {
              alert('An error has occured during Invitation request!');
              console.log(error);
            });
        }
      }
    }
  };
  function removePhoto(e) {
    let removeData = null;
    for (let idx = 0; idx < data.users.length; idx += 1) {
      if (data.users[idx]._id === e.target.id) {
        removeData = data.users[idx];
        break;
      }
    }
    if (removeData == null || confirm(`Remove photo for ${removeData.first} ${removeData.last}?`) === false) {
      console.log('Do not delete');
      return;
    }
    if (removeData !== null) {
      fetch(`${REACT_APP_API_ENDPOINT}/ufileup`, {
        method: 'DELETE',
        headers: {
          Authorization: `JWTToken ${usr.token}`,
        },
        body: JSON.stringify(removeData),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          // window.location.reload();
          if (responseJson && responseJson.response && responseJson.response !== 'OK') {
            alert(responseJson.response);
          }
          setReload(!reload);
        })
        .catch((error) => {
          alert('An error has occured during Removal!');
        });
    }
  }
  const customerGridImage = (props) => (
    <div className="image flex gap-4">
      {!props.image
        ? <input type="file" name="userfile" id={props._id} onChange={handleChange} />
        : (
          <div>
            <img
              className="rounded-full w-10 h-10"
              src={props.image && props.image.length > 0 ? props.image : profile}
              alt="employee"
            />
            <font color="black"><input type="submit" name="remove" id={props._id} value="&#x007F;" onClick={removePhoto} /></font>
          </div>
        )}
    </div>
  );
  if (loading) {
    return <p>Loading... </p>;
  }
  const orgData = [];
  data.organization.forEach((org) => {
    orgData.push({ id: org._id, value: org.title });
  });
  const organizations = {
    params: {
      actionComplete: () => false,
      allowFiltering: true,
      dataSource: data.organization,
      fields: { value: '_id', text: 'title' },
      query: new Query(),
    },
  };
  const roles = {
    params: {
      actionComplete: () => false,
      allowFiltering: true,
      dataSource: data.roles,
      fields: { value: '_id', text: 'name' },
      query: new Query(),
    },
  };
  const actionComplete = (args) => {
    const editedData = args.data;
    const cols = grid.columns;
    for (const col of cols) {
      if (col.headerText === 'Image') {
        col.allowEditing = false;
      }
    }
    /** Set initial Focus */
    if (args.requestType === 'beginEdit') {
      args.form.elements.namedItem('first').focus();
      // eslint-disable-next-line prefer-destructuring
      const dialog = args.dialog;
      dialog.header = `Details of ${args.rowData.first} ${args.rowData.last}`;
    } else if (args.form && args.requestType === 'add') {
      args.form.elements.namedItem('first').focus();
    } else if (args.requestType === 'save' && args.form && args.action === 'add') {
      if (args.data) {
        // Here you can send the updated data to your server using AJAX call
        orgData.forEach((element) => {
          if (element.id === editedData.organizationid) {
            editedData.organizationid = element.id;
            editedData.organization = element.value;
          }
        });
        fetch(`${REACT_APP_API_ENDPOINT}/users`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(editedData),
        })
          .then((response) => response.json())
          .then((responseJson) => {
            // The added/edited data will be saved in the Grid
            // The default edit operation is cancelled
            if (responseJson && responseJson.response && responseJson.response !== 'OK') {
              alert(responseJson.response);
              args.cancel = true;
            }
            if (responseJson && responseJson._id) {
              args.data._id = responseJson._id;
            }
            setReload(!reload);
          })
          .catch((error) => {
            console.log(error);
            args.cancel = true;
          });
      }
    } else if (args.requestType === 'save' && args.form && args.action === 'edit') {
      if (args.data) {
        // The default edit operation is cancelled
        // Here you can send the updated data to your server using AJAX call

        orgData.forEach((element) => {
          if (element.id === editedData.organizationid) {
            editedData.organizationid = element.id;
            editedData.organization = element.value;
          }
        });
        fetch(`${REACT_APP_API_ENDPOINT}/users`, {
          method: 'UPDATE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(editedData),
        })
          .then((response) => response.json())
          .then((responseJson) => {
            // The added/edited data will be saved in the Grid
            if (responseJson && responseJson.response && responseJson.response !== 'OK') {
              alert(responseJson.response);
              args.cancel = true;
            }
            setReload(!reload);
          })
          .catch((error) => {
            console.log(error);
            args.cancel = true;
          });
      }
    } else if (args.requestType === 'delete') {
      let entry = ' this entry?';
      if (args.data.length > 1) {
        entry = ' these entries?';
      }
      if (args.data) {
        // The default edit operation is cancelled
        // Here you can send the updated data to your server using AJAX call
        fetch(`${REACT_APP_API_ENDPOINT}/users`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(editedData),
        })
          .then((response) => response.json())
          .then((responseJson) => {
            // The added/edited data will be saved in the Grid
            if (responseJson && responseJson.response && responseJson.response !== 'OK') {
              alert(responseJson.response);
              args.cancel = true;
            }
            setReload(!reload);
          })
          .catch((error) => {
            console.log(error);
            args.cancel = true;
          });
      }
    }
  };
  function setUserPreferences(pref, val) {
    if (!data.user.preferences) {
      data.user.preferences = {};
    }
    if (!data.user.preferences.users) {
      data.user.preferences.users = {};
    }
    if (pref === 'group') {
      data.user.preferences.users = { group: val, sort: data.user.preferences.users.sort };
    } else if (pref === 'sort') {
      data.user.preferences.users = { group: data.user.preferences.users.group, sort: val };
    }
    // Remove the New Password to avoid creating a new password
    data.user.newPassword = '';
    // Avoid Password comparison
    data.user.password = '';
    fetch(`${REACT_APP_API_ENDPOINT}/user`, {
      method: 'UPDATE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWTToken ${usr.token}`,
      },
      body: JSON.stringify(data.user),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson && responseJson.response && responseJson.response !== 'OK') {
          alert(responseJson.response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const actionBegin = (args) => {
    if (args.requestType === 'ungrouping' || args.requestType === 'grouping') {
      const index = groups.indexOf(args.columnName);
      if (index > -1 && args.requestType === 'ungrouping') { // only splice array when item is found
        groups.splice(index, 1); // 2nd parameter means remove one item only
      } else if (index === -1 && args.requestType === 'grouping') { // only splice array when item is found
        groups.push(args.columnName); // 2nd parameter means remove one item only
      }
      setUserPreferences('group', groups);
    } else if (args.requestType === 'sorting') {
      setUserPreferences('sort', [{ field: args.columnName, direction: args.direction }]);
    }
    const cols = grid.columns;
    for (const col of cols) {
      if (col.headerText === 'Image') {
        if (args.requestType === 'add' && args.type === 'beginEdit') {
          col.allowEditing = false;
          col.visible = false;
        } else {
          col.visible = true;
        }
      }
      if (col.headerText === 'ID') {
        if (args.requestType === 'add' && args.type === 'beginEdit') {
          col.visible = false;
        } else {
          col.visible = false;
        }
      }

      if (col.headerText === 'Password') {
        if (args.requestType === 'add') {
          col.allowEditing = true;
          col.visible = true;
        } else {
          col.allowEditing = false;
          col.visible = false;
        }
      }
    }
  };
  let crudUpdate = false;
  let crudCreate = false;
  let crudDelete = false;
  if (data.role && data.role.users) {
    if (data.role.users.permissions.includes('Create')) {
      crudCreate = true;
    }
    if (data.role.users.permissions.includes('Update')) {
      crudUpdate = true;
    }
    if (data.role.users.permissions.includes('Delete')) {
      crudDelete = true;
    }
    if (!data.role.users.permissions.includes('Read')) {
      return <p>Not authorized...</p>;
    }
  }
  const editing = {
    allowEditing: crudUpdate,
    allowAdding: crudCreate,
    allowDeleting: crudDelete,
    showConfirmDialog: true,
    showDeleteConfirmDialog: true,
    mode: 'Dialog',
  };
  function rowClicked() {
    if (crudCreate) {
      setDetails(false);
    }
  }
  function onFileUpload(args) {
    // add addition data as key-value pair.
    args.customFormData = [{ organizationid: data.user.organizationid }];
  }
  function completeUpload(args) {
    window.location.reload();
  }
  let uploadObj;
  const path = {
    removeUrl: `${REACT_APP_API_ENDPOINT}/usersremove`,
    saveUrl: `${REACT_APP_API_ENDPOINT}/usersupload`,
  };
  const buttons = { browse: 'Choose File', clear: 'Clear All', upload: 'Upload All' };

  const FilterOptions = {
    type: 'FilterBar',
    showFilterBarStatus: true,
    mode: 'Immediate',
  };
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl zackat-div">
      <Header category="" title="Users" />
      <GridComponent
        dataSource={data.users}
        enableHover={false}
        allowPaging
        pageSettings={{ pageCount: 5 }}
        toolbar={toolbarOptions}
        toolbarClick={clickHandler}
        editSettings={editing}
        allowSorting
        allowResizing
        allowGrouping
        groupSettings={groupOptions}
        sortSettings={sortOptions}
        allowFiltering={data && data.users && data.users.length > 0}
        filterSettings={FilterOptions}
        actionComplete={actionComplete}
        actionBegin={actionBegin}
        recordClick={rowClicked}
        // showColumnChooser
        allowTextWrap
        ref={(g) => { grid = g; }}
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {/* usersGrid.map((item, index) => <ColumnDirective key={index} {...item} />) */}
          <ColumnDirective
            field="_id"
            headerText="ID"
            width="100"
            format="C2"
            visible={false}
            textAlign="Left"
            isPrimaryKey
          />
          <ColumnDirective
            headerText="Image"
            width="80"
            textAlign="Center"
            template={customerGridImage}
          />
          <ColumnDirective
            field="first"
            headerText="First"
            width="120"
            textAlign="Left"
            validationRules={valuerules}
          />
          <ColumnDirective
            field="last"
            headerText="Last"
            width="120"
            textAlign="Left"
            validationRules={valuerules}
          />
          <ColumnDirective
            field="email"
            headerText="Email"
            validationRules={valuerules}
            width="120"
            textAlign="Left"
          />
          <ColumnDirective
            field="mobile"
            headerText="Mobile"
            width="120"
            textAlign="Left"
          />
          <ColumnDirective
            field="password"
            headerText="Password"
            width="100"
            visible={false}
            textAlign="Left"
          />
          <ColumnDirective
            field="organizationid"
            headerText="Organization"
            width="100"
            textAlign="Left"
            editType="dropdownedit"
            validationRules={valuerules}
            visible
            foreignKeyField="_id"
            foreignKeyValue="title"
            dataSource={organizations.params.dataSource}
          />
          <ColumnDirective
            field="role"
            headerText="Role"
            width="100"
            textAlign="Left"
            editType="dropdownedit"
            validationRules={valuerules}
            visible
            foreignKeyField="_id"
            foreignKeyValue="name"
            dataSource={roles.params.dataSource}
          />
        </ColumnsDirective>
        {/* ColumnChooser */}
        <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter, Search, Resize, ForeignKey, LazyLoadGroup, Group]} />
      </GridComponent>
      <div id="droparea">
        {crudCreate === true && (
          <UploaderComponent
            id="fileupload"
            type="file"
            ref={(uplaod) => { uploadObj = uplaod; }}
            asyncSettings={path}
            buttons={buttons}
            multiple={false}
            actionComplete={completeUpload}
            autoUpload={false}
            uploading={onFileUpload}
            allowedExtensions=".csv"
          />
        )}
      </div>
    </div>
  );
};

export default Users;
