/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-cycle */
import React, { useState, useEffect } from 'react';
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
  Selection, Toolbar, Edit, Sort, Filter, ColumnChooser, Resize,
  ExcelExport,
} from '@syncfusion/ej2-react-grids';

import { DropDownListComponent, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import axios from 'axios';

import { Header } from '../components';
import { coreBodyEventsGrid, reportInvGrid, reportAuditGrid, reportNotificationsGrid } from '../data/dummy';
import './Zackat.css';

const Reports = () => {
  const toolbarOptions = ['Search', 'ExcelExport'];
  const usr = JSON.parse(localStorage.getItem('user'));
  const org = JSON.parse(localStorage.getItem('organization'));

  const [associateid, setAssociateId] = useState('');
  const editing = { allowDeleting: false, allowEditing: false };
  const { REACT_APP_API_ENDPOINT } = process.env;

  const [corebody, setCoreBody] = useState(false);
  const [inventory, setInventory] = useState(false);
  const [associates, setAssociates] = useState(false);
  const [audit, setAudit] = useState(false);
  const [selectedReport, setReport] = useState('');
  const [tableData, setTableData] = useState([]);
  const minDate = new Date('10/01/2024 00:00 AM');
  let maxDate = new Date();
  maxDate = new Date(maxDate.setDate(maxDate.getDate() + 1));
  let fromDate = new Date();
  fromDate = new Date(fromDate.setDate(maxDate.getDate() - 1));
  const toDate = new Date();
  const [from, setFrom] = useState(fromDate.getTime() / 1000);
  const [to, setTo] = useState(maxDate.getTime() / 1000);

  async function reportSelect(e) {
    setReport(e.value);
    if (e.value === 'CoreBody') {
      setCoreBody(true);
    } else {
      setCoreBody(false);
    }
    if (e.value === 'Low Battery') {
      setInventory(true);
    } else {
      setInventory(false);
    }
    if (e.value === 'Notifications') {
      setAssociates(true);
      axios({
        url: `${REACT_APP_API_ENDPOINT}/reports?report=notifications&_id=${usr._id}&organizationid=${org.id}&from=${from}&to=${to}`,
        method: 'get',
        headers: {
          Authorization: `JWTToken ${usr.token}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          setTableData(response.data.notifications);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setAssociates(false);
    }
    if (e.value === 'Audit') {
      setAudit(true);
      axios({
        url: `${REACT_APP_API_ENDPOINT}/reports?report=audit&_id=${usr._id}&organizationid=${org.id}&from=${from}&to=${to}`,
        method: 'get',
        headers: {
          Authorization: `JWTToken ${usr.token}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          setTableData(response.data.audit);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setAudit(false);
    }
  }
  async function deviceSelect(e) {
    let stringData = '';
    for (let i = 0; i < e.value.length; i += 1) {
      stringData += `&devices=${e.value[i]}`;
    }
    axios({
      url: `${REACT_APP_API_ENDPOINT}/reports?report=lowbattery&_id=${usr._id}${stringData}&from=${from}&to=${to}`,
      method: 'get',
      headers: {
        Authorization: `JWTToken ${usr.token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        setTableData(response.data.inventory);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  async function associateSelect(e) {
    if (!e || !e.value) {
      return;
    }
    setAssociateId(e.value);
    axios({
      url: `${REACT_APP_API_ENDPOINT}/reports?report=corebody&associateid=${e.value}&_id=${usr._id}&from=${from}&to=${to}`,
      method: 'get',
      headers: {
        Authorization: `JWTToken ${usr.token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        /*
        console.log(response.data.coreBody);
        const newArray = [...response.data.coreBody];
        newArray.reverse();

        setTableData(newArray);
        */
        setTableData(response.data.coreBody);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function dateChanged(e) {
    if (!e || !e.value) {
      return;
    }
    const d1 = new Date(e.value);
    if (e.target.id === 'fromdate') {
      setFrom(d1.getTime() / 1000);
      // console.log(from, to, associateid);
    }
    if (e.target.id === 'todate') {
      setTo(d1.getTime() / 1000);
    }
    if (selectedReport === 'Audit') {
      reportSelect({ value: 'Audit' });
    } else if (selectedReport === 'CoreBody') {
      associateSelect({ value: associateid });
    } else if (selectedReport === 'Notifications') {
      reportSelect({ value: 'Notifications' });
    }
  }
  const fields = { value: 'value', text: 'text' };
  const associateFields = { text: 'name', value: '_id' };

  // const { loading, data } = Methods('coreBodyTempEvents', 'GET', associateid);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const selectedOrg = JSON.parse(localStorage.getItem('organization'));
  useEffect(() => {
    // limit associates to the overall selection
    if (selectedOrg && selectedOrg.id && selectedOrg.id.length > 0) {
      usr.organizationid = selectedOrg.id;
    }
    usr.report = 'reports';
    // fetch data
    const dataFetch = async () => {
      const query = Object.entries(usr)
        .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
        .join('&');
      const url = `${REACT_APP_API_ENDPOINT}/reports?${query}`;
      const mydata = await (
        await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
        })
      ).json();

      // set state when the data received
      setData(mydata);
      setLoading(false);
    };
    dataFetch();
  }, []);

  let grid;
  const clickHandler = (args) => {
    if (grid && args.item.properties.text === 'Excel Export') {
      const properties = {
        fileName: `ExcelExport_${selectedReport}_${new Date().toISOString()}.xlsx`,
      };
      grid.excelExport(properties);
    }
  };
  if (loading) {
    return <p>Loading... </p>;
  }
  const FilterOptions = {
    type: 'Menu',
  };
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl zackat-div">
      <div className="flex">
        <DropDownListComponent id="ddlelement" dataSource={data.reports} placeholder="Select a report" onChange={reportSelect} />
        {(corebody || audit || associates) && <DateTimePickerComponent id="fromdate" onChange={dateChanged} placeholder="Select From Date/Time" min={minDate} max={maxDate} />}
        {(corebody || audit || associates) && <DateTimePickerComponent id="todate" onChange={dateChanged} placeholder="Select To Date/Time" min={minDate} max={maxDate} />}
        {inventory
        && <MultiSelectComponent id="deviceSelect" dataSource={data.devices} fields={fields} onChange={deviceSelect} placeholder="Select device(s)" />}
        {corebody
        && <DropDownListComponent id="associateSelect" dataSource={data.associates} fields={associateFields} onChange={associateSelect} placeholder="Select Inventory (Associate)" />}
      </div>
      <Header category="" title={selectedReport} />
      {(corebody || inventory || associates || audit) && (
      <GridComponent
        dataSource={tableData}
        width="auto"
        allowPaging
        allowSorting
        editSettings={editing}
        toolbar={toolbarOptions}
        toolbarClick={clickHandler}
        enableHover={false}
        pageSettings={{ pageSize: 30 }}
        allowResizing
        allowFiltering
        filterSettings={FilterOptions}
        allowExcelExport
        showColumnChooser
        allowTextWrap
        ref={(g) => { grid = g; }}
      >
        <ColumnsDirective>
          {corebody && coreBodyEventsGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
          {inventory && reportInvGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
          {associates && reportNotificationsGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
          {audit && reportAuditGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
        </ColumnsDirective>
        <Inject services={[Search, Page, Selection, Toolbar, Edit, Sort, Filter, ColumnChooser, Resize, ExcelExport]} />
      </GridComponent>
      )}
    </div>
  );
};
export default Reports;
