/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable import/no-cycle */
/* eslint-disable no-alert */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useCallback } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import GoogleMapReact from 'google-map-react';

// eslint-disable-next-line import/no-extraneous-dependencies
import { useDropzone } from 'react-dropzone';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { KanbanComponent, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-react-kanban';
import { SwitchComponent } from '@syncfusion/ej2-react-buttons';

import { useSearchParams } from 'react-router-dom';
import { Header } from '.';
// import Henke from '../data/Monaco.kml';

// import { jobsGrid } from '../data/dummy.js';
import '../pages/inventory.css';
import '../pages/Zackat.css';
import zackatPin from '../data/mappin.png';

export const Jobs = () => {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const { REACT_APP_GOOGLE_MAPS_API_KEY } = process.env;
  const { REACT_APP_GOOGLE_MAPS_ID } = process.env;
  const [searchParams] = useSearchParams();
  let orgId = searchParams.get('id');
  let name = `${searchParams.get('name')}`;
  const [kmlData, setKmlData] = useState([]);
  const [mgrData, setMgrData] = useState([]);
  const [mapDisplay, setMapDisplay] = useState(true);
  const [polyup, setPolyUp] = useState(false);
  // const [card, setCard] = useState({});

  const defaultMapOptions = {
    fullscreenControl: true,
    zoomControl: true,
    mapTypeControl: true,
    scaleControl: true,
    streetViewControl: false,
    rotateControl: true,
  };
  function changeMapDisplay() {
    setMapDisplay(!mapDisplay);
  }
  async function renderMarkers(map, maps, point, pointname) {
    const newCenter = { lng: Number(point[0]), lat: Number(point[1]) };
    // const mypin = 'http://maps.google.com/mapfiles/kml/pushpin/ylw-pushpin.png';
    const marker = new maps.Marker({
      position: newCenter,
      map,
      icon: {
        // path: maps.SymbolPath.CIRCLE,
        url: zackatPin,
        scaledSize: new maps.Size(35, 35),
        scale: 10,
      },
      title: pointname,
    });
    map.setCenter(newCenter);
    return marker;
  }

  let kanbanObj;
  const jobsname = `Jobs: ${name}`;
  const usr = JSON.parse(localStorage.getItem('user'));
  const selectedOrg = JSON.parse(localStorage.getItem('organization'));
  if (!orgId || orgId === 'null') {
    if (selectedOrg) {
      orgId = selectedOrg.id;
      name = `${selectedOrg.name}`;
    } else if (usr && usr.organizationid) {
      orgId = usr.organizationid;
      name = '';
    }
  }
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      const filedata = new FormData();
      reader.onabort = () => alert('file reading was aborted');
      reader.onerror = () => alert('file reading has failed');
      reader.onload = () => {
        // Do whatever you want with the file contents
        filedata.append('userfile', file);
        filedata.append('fileName', file.name);
        filedata.append('organizationid', orgId);
        filedata.append('organization', name);
        fetch(`${REACT_APP_API_ENDPOINT}/kmlfileup`, {
          method: 'POST',
          headers: {
            Authorization: `JWTToken ${usr.token}`,
          },
          body: filedata,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            setTimeout(
              setPolyUp(!polyup),
              1000,
            );
          })
          .catch((error) => {
            alert('An error has occured during upload of KML file!');
          });
      };
      reader.readAsArrayBuffer(file);
    }, []);
  });
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const fields = [
    { text: 'Title', key: 'title', type: 'TextBox' },
    { text: 'Status', key: 'status', type: 'DropDown' },
    { text: 'Description', key: 'description', type: 'TextBox' },
    { text: 'Date', key: 'date', type: 'TextBox' },
    { text: 'Time', key: 'time', type: 'TextBox' },
    { text: 'Radius<br />(in meters)', key: 'radius', placeHolder: 'Radius in meters from center point', type: 'TextBox' },
  ];
  //    { text: 'Manager', key: 'manager', type: 'DropDown' },

  useEffect(() => {
    // limit associates to the overall selection
    if (selectedOrg && selectedOrg.id && selectedOrg.id.length > 0) {
      usr.organizationid = orgId;
      usr.organization = name;
    }
    // fetch data
    const dataFetch = async () => {
      try {
        const query = Object.entries(usr)
          .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
          .join('&');
        const url = `${REACT_APP_API_ENDPOINT}/jobs?${query}`;
        const mydata = await (
          await fetch(url, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `JWTToken ${usr.token}`,
            },
          })
        ).json();
        const mylocs = [];
        if (mydata.locations) {
          mydata.locations.forEach((kml) => {
            mylocs.push({ value: kml._id, text: kml.document.name });
          });
        }
        const mymgrs = [];
        if (mydata.managers) {
          mydata.managers.forEach((mgr) => {
            mymgrs.push({ value: mgr._id, text: `${mgr.first} ${mgr.last}` });
          });
        }
        setKmlData(mylocs);
        setMgrData(mymgrs);
        setData(mydata);
        setLoading(false);
      } catch (err) {
        setData([]);
        setLoading(false);
      }
    };
    // set state when the data received
    dataFetch();
  }, [polyup, mapDisplay]);

  const actionComplete = (args) => {
    /** Set initial Focus */
    if (args.requestType === 'cardCreated') {
      if (args.addedRecords && confirm(`Would you really like to add this entry for Organization ${name}?`)) {
        // The default edit operation is cancelled
        // Here you can send the updated data to your server using AJAX call
        const editedData = args.addedRecords;
        if (editedData.length > 0) {
          editedData[0].organizationid = orgId;
          editedData[0].organization = name;
          editedData[0].location = { _id: '', name: '' };
        } else {
          editedData.organizationid = orgId;
          editedData.organization = name;
          editedData.location = { _id: '', name: '' };
        }
        fetch(`${REACT_APP_API_ENDPOINT}/jobs`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(editedData),
        })
          .then((response) => response.json())
          .then((responseJson) => {
            if (responseJson && responseJson.response && responseJson.response !== 'OK') {
              alert(responseJson.response);
            }
          })
          .catch((error) => {
            alert(error);
          });
      }
    } else if (args.requestType === 'cardChanged') {
      if (args) {
        // The default edit operation is cancelled
        // Here you can send the updated data to your server using AJAX call
        const editedData = args.changedRecords;
        if (editedData.length > 0) {
          editedData[0].organizationid = orgId;
          editedData[0].organizationname = name;
        } else {
          editedData.organizationid = orgId;
          editedData.organization = name;
        }
        fetch(`${REACT_APP_API_ENDPOINT}/jobs`, {
          method: 'UPDATE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(editedData),
        })
          .then((response) => response.json())
          .then((responseJson) => {
            // The added/edited data will be saved in the Grid
            if (responseJson && responseJson.response && responseJson.response !== 'OK') {
              alert(responseJson.response);
            }
          })
          .catch((error) => {
            alert(error);
          });
      }
    } else if (args.requestType === 'cardRemoved') {
      const editedData = args.deletedRecords;
      if (editedData) {
        // The default edit operation is cancelled
        // Here you can send the updated data to your server using AJAX call
        if (editedData.length > 0) {
          editedData[0].organizationid = orgId;
          editedData[0].organizationid = name;
        } else {
          editedData.organizationid = orgId;
          editedData.organizationid = name;
        }
        fetch(`${REACT_APP_API_ENDPOINT}/jobs`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(editedData),
        })
          .then((response) => response.json())
          .then((responseJson) => {
            // The added/edited data will be saved in the Grid
            if (responseJson && responseJson.response && responseJson.response !== 'OK') {
              alert(responseJson.response);
            }
          })
          .catch((error) => {
            alert(error);
          });
      }
    }
  };
  function locationUpdated(e) {
    let found = -1;
    const kid = e.target.id.replace('KML_', '');
    console.log('locationUpdated', kid, e.target.id);
    for (let i = 0; i < data.jobs.length; i += 1) {
      if (kid === data.jobs[i].Id) {
        console.log('Update this', kid);
        found = i;
        break;
      }
    }
    if (found !== -1 && kanbanObj && data.jobs[found]) {
      if (e.target.itemData.value !== data.jobs[found].location._id) {
        if (confirm(`Update Job ${data.jobs[found].title} to Location to ${e.value}`)) {
          // eslint-disable-next-line prefer-object-spread
          const carddata = {
            Id: data.jobs[found].Id,
            date: data.jobs[found].date,
            description: data.jobs[found].description,
            location: {
              _id: e.target.itemData.value,
              name: e.target.itemData.text,
            },
            manager: data.jobs[found].manager,
            organization: data.jobs[found].organization,
            organizationid: data.jobs[found].organizationid,
            status: data.jobs[found].status,
            time: data.jobs[found].time,
            timestamp: data.jobs[found].timestamp,
            title: data.jobs[found].title,
          };
          const cards = {};
          cards.requestType = 'cardChanged';
          const ary = [];
          ary.push(carddata);
          cards.changedRecords = ary;
          actionComplete(cards);
          data.jobs[found].location = carddata.location;
          setTimeout(
            () => {
              setPolyUp(!polyup);
              window.location.reload();
            },
            1000,
          );
        }
      }
    }
  }

  function managerUpdated(e) {
    let found = -1;
    for (let i = 0; i < data.jobs.length; i += 1) {
      if (e.target.id === data.jobs[i].Id) {
        found = i;
        break;
      }
    }
    if (found !== -1 && kanbanObj && data.jobs[found]) {
      if (e.value !== data.jobs[found].manager) {
        if (confirm(`Update Job  ${data.jobs[found].title} set Manager to ${e.value}`)) {
          // eslint-disable-next-line prefer-object-spread
          data.jobs[found].manager = e.value;
          const carddata = {
            Id: data.jobs[found].Id,
            date: data.jobs[found].date,
            description: data.jobs[found].description,
            location: data.jobs[found].location,
            manager: e.value,
            organization: data.jobs[found].organization,
            organizationid: data.jobs[found].organizationid,
            status: data.jobs[found].status,
            time: data.jobs[found].time,
            timestamp: data.jobs[found].timestamp,
            title: data.jobs[found].title,
          };
          const cards = {};
          cards.requestType = 'cardChanged';
          const ary = [];
          ary.push(carddata);
          cards.changedRecords = ary;
          actionComplete(cards);
        }
      }
    }
  }
  function cardTemplate(props) {
    return (
      <div className="card-template" style={{ height: mapDisplay ? '1000px' : '300px', marginBottom: '0' }}>
        <div
          style={{
            border: '2px solid darkgrey',
            position: 'absolute',
            zIndex: 1,
            top: 2,
            marginBottom: 'auto',
            width: '100%', // or you can use width: '100vw'
            height: '100%', // or you can use height: '100vh'
          }}
          className="e-card-content e-card-separator"
        >
          <table className="card-template-wrap">
            <tbody>
              <tr>
                <td className="CardHeader">Job:</td>
                <td>
                  {props.title ? props.title : 'unassigned'}
                </td>
              </tr>
              <tr>
                <td className="CardHeader">Description:</td>
                <td>
                  {props.description ? props.description : 'unassigned'}
                </td>
              </tr>
              <tr>
                <td className="CardHeader">Manager:</td>
                <td>
                  <DropDownListComponent
                    id={props.Id}
                    dataSource={mgrData}
                    sortOrder="Ascending"
                    defaultValue={props.manager ? props.manager : ''}
                    onChange={managerUpdated}
                    fields={mgrData.fields}
                    placeholder="Select Manager"
                  />
                </td>
              </tr>
              <tr>
                <td className="CardHeader">Date:</td>
                <td>
                  {props.date ? props.date : 'unassigned'}
                </td>
              </tr>
              <tr>
                <td className="CardHeader">Time:</td>
                <td>
                  {props.time ? props.time : 'unassigned'}
                </td>
              </tr>
              <tr>
                <td className="CardHeader">Location:</td>
                <td>
                  <DropDownListComponent
                    id={`KML_${props.Id}`}
                    dataSource={kmlData}
                    sortOrder="Descending"
                    value={props.location.name ? props.location.name : ''}
                    fields={kmlData.fields}
                    onChange={locationUpdated}
                    placeholder="Job Location/KML"
                  />
                </td>
              </tr>
              {props.location.name && props.location.name.length > 0 && (
                <tr>
                  <td className="CardHeader">Radius:</td>
                  <td title="Radius in meters from center point">
                    {props.radius ? props.radius : ''}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {mapDisplay && (
            <div style={{
              position: 'absolute',
              zIndex: 0,
              marginTop: 'auto',
              width: '100%', // or you can use width: '100vw'
              height: '100vh', // or you can use height: '100vh'
            }}
            >
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: REACT_APP_GOOGLE_MAPS_API_KEY,
                  mapId: { REACT_APP_GOOGLE_MAPS_ID },
                  mapTypeId: 'terrain',
                }}
                defaultCenter={{
                  lng: -96.8172843880738, lat: 33.09087757313318,
                }}
                defaultZoom={16}
                yesIWantToUseGoogleMapApiInternals
                options={defaultMapOptions}
                onGoogleApiLoaded={({ map, maps }) => {
                  // Define the LatLng coordinates for the polygon's path.
                  const coords = [];
                  let path = false;
                  if (data.locations) {
                    data.locations.forEach((element) => {
                      if (props.location.name === element.document.name) {
                        if (element.document && element.document.placemark && element.document.placemark.length > 0) {
                          element.document.placemark.forEach((place) => {
                            if (place.polygon && place.polygon.outerBoundaryIs && place.polygon.outerBoundaryIs.linearRing && place.polygon.outerBoundaryIs.linearRing.poly) {
                              place.polygon.outerBoundaryIs.linearRing.poly.forEach((coord) => {
                                const c = { lat: Number(coord.lat), lng: Number(coord.lng) };
                                coords.push(c);
                              });
                              if (place.polygon && place.polygon.outerBoundaryIs && place.polygon.outerBoundaryIs.linearRing && place.polygon.outerBoundaryIs.linearRing.path) {
                                place.polygon.outerBoundaryIs.linearRing.path.forEach((coord) => {
                                  const c = { lat: Number(coord.lat), lng: Number(coord.lng) };
                                  coords.push(c);
                                });
                              }
                            }
                          });
                          if (!path && coords.length > 0) {
                            setTimeout(() => {
                              const newCenter = coords[0];
                              map.setCenter(newCenter);
                              console.log('new Center', newCenter);
                            }, 1000);
                          }
                          if (element.document && element.document.placemark[0] && element.document.placemark[0].point && element.document.placemark[0].point.coordinates) {
                            const point = element.document.placemark[0].point.coordinates.split(',');
                            renderMarkers(map, maps, point, element.document.placemark[0].name);
                          }
                        }
                        // Try Folder
                        if (element.document && element.document.folder && element.document.folder.placemark && element.document.folder.placemark.length > 0) {
                          element.document.folder.placemark.forEach((place) => {
                            if (place.polygon && place.polygon.outerBoundaryIs && place.polygon.outerBoundaryIs.linearRing && place.polygon.outerBoundaryIs.linearRing.poly) {
                              place.polygon.outerBoundaryIs.linearRing.poly.forEach((coord) => {
                                const c = { lat: Number(coord.lat), lng: Number(coord.lng) };
                                path = false;
                                coords.push(c);
                              });
                            }
                            if (place.polygon && place.polygon.outerBoundaryIs && place.polygon.outerBoundaryIs.linearRing && place.polygon.outerBoundaryIs.linearRing.path) {
                              place.polygon.outerBoundaryIs.linearRing.path.forEach((coord) => {
                                const c = { lat: Number(coord.lat), lng: Number(coord.lng) };
                                path = true;
                                coords.push(c);
                              });
                            }
                          });
                          if (path && coords.length > 0) {
                            setTimeout(() => {
                              const newCenter = coords[0];
                              //                              map.setCenter(newCenter);
                              console.log('path new Center', newCenter);
                              map.setZoom(10);
                            }, 1000);
                          }
                          if (element.document.placemark && element.document.placemark[0] && element.document.placemark[0].point && element.document.placemark[0].point.coordinates) {
                            const point = element.document.placemark[0].point.coordinates.split(',');
                            renderMarkers(map, maps, point, element.document.placemark[0].name);
                          }
                          if (element.document.folder.placemark && element.document.folder.placemark[0] && element.document.folder.placemark[0].point && element.document.folder.placemark[0].point.coordinates) {
                            const point = element.document.folder.placemark[0].point.coordinates.split(',');
                            console.log(element.document);
                            renderMarkers(map, maps, point, element.document.folder.placemark[0].name);
                          }
                        }
                      }
                    });
                  }
                  // Construct the polygon.
                  console.log(coords);
                  const poly = new maps.Polygon({
                    paths: coords,
                    strokeColor: '#366976',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: !path ? '#F6821E' : 'wheat',
                    fillOpacity: 0.85,
                  });
                  poly.setMap(map);
                }}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
  function columnTemplate(props) {
    return (
      <div className="header-template-wrap">
        <div className={`header-icon e-icons ${props.keyField}`} />
        <div className="header-text">{props.headerText}</div>
      </div>
    );
  }
  if (loading) {
    return <p>Loading Jobs...</p>;
  }
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl zackat-div">
      <Header category="" title={jobsname} />
      <div
        {...getRootProps()}
        className="zackat-div"
        style={{
          alignContent: 'center',
          height: '60px',
          width: 'auto',
          // backgroundImage: 'url("https://monaco.zackat.com/logo.png")',
          backgroundColor: '#F6821E',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          color: '#366976',
        }}
      >
        <input {...getInputProps()} />
        {
          isDragActive
            ? <p>   Drop the files here ...</p>
            : (
              // eslint-disable-next-line jsx-a11y/label-has-associated-control
              <p>
                  Drag-n-drop KML file here, or click to select file
              </p>
            )
        }
      </div>
      <label id="showMapLbl" htmlFor="showMap" style={{ padding: '10px 40px 10px 0' }}>
        Show Maps
      </label>
      <SwitchComponent id="showMap" checked={mapDisplay} cssClass="bar-color handle-color" onChange={changeMapDisplay} />
      <KanbanComponent
        cssClass="kanban-overview"
        id="kanban"
        keyField="status"
        dataSource={data.jobs}
        dialogSettings={{ fields, className: 'e-dialog-delete' }}
        actionComplete={actionComplete}
        allowDragAndDrop
        enablePersistence={false}
        swimlaneSettings={{ keyField: 'organization', allowDragAndDrop: true }}
        ref={(kanban) => { kanbanObj = kanban; }}
        cardSettings={{
          headerField: 'Id',
          selectionType: 'Single',
          contentField: 'description',
          template: cardTemplate,
        }}
      >
        <ColumnsDirective>
          <ColumnDirective headerText="Active" keyField="active" template={columnTemplate} showAddButton />
          <ColumnDirective headerText="Completed" keyField="completed" template={columnTemplate} showAddButton />
          <ColumnDirective headerText="Unscheduled" keyField="unscheduled" template={columnTemplate} showAddButton />
          <ColumnDirective headerText="In Active" keyField="inactive" template={columnTemplate} showAddButton />
        </ColumnsDirective>
      </KanbanComponent>
    </div>
  );
};

export default Jobs;
