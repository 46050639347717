/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-cycle */
import React, { useState, useEffect, useRef } from 'react';
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
  Selection, Toolbar, Edit, Sort, Filter, ColumnChooser, Resize,
} from '@syncfusion/ej2-react-grids';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import Header from './Header';
import { eventsGrid } from '../data/dummy';
import '../pages/Zackat.css';

const Events = () => {
  const [pauseEnable, setPause] = useState(false);
  const [resumeEnable, setResume] = useState(true);
  const pause = { text: 'Pause', tooltipText: 'Pause Refresh', prefixIcon: 'e-repeat', id: 'pause', disabled: pauseEnable };
  const resume = { text: 'Resume', tooltipText: 'Resume Refresh', prefixIcon: 'e-repeat', id: 'resume', disabled: resumeEnable };
  const toolbarOptions = ['Delete', 'Search', pause, resume];
  const [searchParams] = useSearchParams();
  const associateid = searchParams.get('id');
  const associatename = `Events for ${searchParams.get('name')}`;
  const editing = { allowDeleting: false, allowEditing: false };
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const timerid = useRef();
  const usr = JSON.parse(localStorage.getItem('user'));
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios({
          url: `${REACT_APP_API_ENDPOINT}/events?associateid=${associateid}`,
          method: 'get',
          headers: {
            Authorization: `JWTToken ${usr.token}`,
            'Content-Type': 'application/json',
          },
        });
        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchData();
  }, []);

  /*
  const { loading, data } = Methods('events', 'GET', associateid);
  */
  async function fetchEvents() {
    const loc = window.location;
    if (loc.pathname !== '/events') {
      setPause(true);
      setResume(true);
      timerid.current = clearInterval(timerid.current);
    }
    try {
      const response = await axios({
        url: `${REACT_APP_API_ENDPOINT}/events?associateid=${associateid}`,
        method: 'get',
        headers: {
          Authorization: `JWTToken ${usr.token}`,
          'Content-Type': 'application/json',
        },
      });
      if (timerid.current) {
        setData(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  }
  // This will run one time after the component mounts
  useEffect(() => {
    // callback function to call when event triggers
    const onPageLoad = () => {
      timerid.current = setInterval(fetchEvents, 30000);
      setTimeout(fetchEvents, 500);
    };

    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad, false);
      // Remove the event listener when component unmounts
      timerid.current = clearInterval(timerid.current);
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  const clickHandler = (args) => {
    if (args.item.properties.text === 'Pause') {
      clearInterval(timerid.current);
      timerid.current = null;
      setPause(true);
      setResume(false);
    } else if (args.item.properties.text === 'Resume') {
      setPause(false);
      setResume(true);
      timerid.current = setInterval(fetchEvents, 30000);
    }
  };
  if (loading) {
    return <p>Loading... </p>;
  }
  const wrapSettings = { wrapMode: 'Both' };
  const FilterOptions = {
    type: 'Menu',
  };
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl zackat-div">
      <Header category="" title={associatename} />
      <GridComponent
        dataSource={data}
        width="auto"
        allowPaging
        allowSorting
        editSettings={editing}
        toolbar={toolbarOptions}
        toolbarClick={clickHandler}
        enableHover={false}
        pageSettings={{ pageSize: 25, pageCount: 10 }}
        allowResizing
        allowFiltering
        filterSettings={FilterOptions}
        showColumnChooser
        allowTextWrap
        textWrapSettings={wrapSettings}
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {eventsGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
        </ColumnsDirective>
        <Inject services={[Search, Page, Selection, Toolbar, Edit, Sort, Filter, ColumnChooser, Resize]} />

      </GridComponent>
    </div>
  );
};
export default Events;
