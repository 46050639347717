/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
/* eslint-disable import/no-cycle */
import React, { useRef, useState, useEffect } from 'react';
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
  Selection, Toolbar, Edit, Sort, Filter,
  ColumnChooser,
  Resize,
} from '@syncfusion/ej2-react-grids';
import { useNavigate } from 'react-router-dom';

import { notificationsGrid } from '../data/dummy';
import { Header } from '../components';
import './Zackat.css';

const NotificationEvents = () => {
  const navigate = useNavigate();
  const usr = JSON.parse(localStorage.getItem('user'));
  if (!usr || usr.loggedIn === false) {
    // Redirect
    navigate('/login?landing=notificationevents');
    return <p>Please login... </p>;
  }
  let grid = useRef(null);
  const details = { text: 'Ack', tooltipText: 'Acknowledge ', prefixIcon: 'e-expand', id: 'ack' };
  const toolbarOptions = ['Delete', 'Search', details];
  const { REACT_APP_API_ENDPOINT } = process.env;

  const editing = { allowDeleting: false, allowEditing: false };
  const selectedOrg = JSON.parse(localStorage.getItem('organization'));
  const [ack, setAck] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // limit associates to the overall selection
    if (selectedOrg && selectedOrg.id && selectedOrg.id.length > 0) {
      usr.organizationid = selectedOrg.id;
    }
    usr.limit = '1000';
    // fetch data
    const dataFetch = async () => {
      const query = Object.entries(usr)
        .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
        .join('&');
      const url = `${REACT_APP_API_ENDPOINT}/notifications?${query}`;
      const mydata = await (
        await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
        })
      ).json();

      // set state when the data received
      setData(mydata);
      setLoading(false);
    };
    dataFetch();
  }, [ack]);

  if (loading) {
    return <p>Loading... </p>;
  }
  const clickHandler = (args) => {
    if (grid && args.item.properties.text === 'Ack') {
      const selectedRecords = grid.getSelectedRecords();
      if (!selectedRecords || selectedRecords.length === 0) {
        alert('Please select a notification');
      } else if (selectedRecords && selectedRecords.length >= 1) {
        const notifications = [];
        const user = { user: usr };
        notifications.notifications = selectedRecords;
        const output = { ...user, ...notifications };

        fetch(`${REACT_APP_API_ENDPOINT}/notifications`, {
          method: 'UPDATE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(output),
        })
          .then((response) => response.json())
          .then((responseJson) => {
            // The added/edited data will be saved in the Grid
            // window.location.reload();
            if (responseJson && responseJson.response && responseJson.response !== 'OK') {
              alert(responseJson.response);
            }
            setAck(!ack);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        alert('Please select a notification');
      }
    }
  };
  const FilterOptions = {
    type: 'Menu',
  };
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl zackat-div">
      <Header category="" title="Notification Events" />
      <GridComponent
        dataSource={data}
        width="auto"
        allowPaging
        allowSorting
        toolbarClick={clickHandler}
        editSettings={editing}
        toolbar={toolbarOptions}
        enableHover={false}
        pageSettings={{ pageSize: 25, pageCount: 10 }}
        allowResizing
        allowFiltering={data && data.length > 0}
        filterSettings={FilterOptions}
        showColumnChooser
        allowTextWrap
        ref={(g) => { grid = g; }}
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {notificationsGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
        </ColumnsDirective>
        <Inject services={[Search, Page, Selection, Toolbar, Edit, Sort, Filter, ColumnChooser, Resize]} />

      </GridComponent>
    </div>
  );
};
export default NotificationEvents;
