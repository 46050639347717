/* eslint-disable max-len */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-restricted-syntax */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import { ToolbarComponent, ItemsDirective, ItemDirective } from '@syncfusion/ej2-react-navigations';
import { getValue } from '@syncfusion/ej2-base';
import { Tooltip } from '@syncfusion/ej2-popups';
import { ButtonComponent, SwitchComponent } from '@syncfusion/ej2-react-buttons';
import React, { useState, useEffect, useRef } from 'react';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Sort,
  Filter,
  Resize,
  Edit,
  Toolbar,
  Page,
  PdfExport,
  ExcelExport,
  ForeignKey,
  LazyLoadGroup,
  VirtualScroll,
  Group,
  columnDataStateChange,
} from '@syncfusion/ej2-react-grids';
// import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Legend, Tooltip, DataLabel, Category, BarSeries, Selection } from '@syncfusion/ej2-react-charts';
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import { useNavigate, Link } from 'react-router-dom';
import { DataManager, Query } from '@syncfusion/ej2-data';
import axios from 'axios';
import { AMap, Header, useWindowDimensions } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import orgpng from '../data/organizations_on orange.png';
import lngpng from '../data/language.png';
import { associatesGrid, gridNameHeader, gridTemperatureHeader, gridActivityHeader, gridBloodpressureHeader, gridFatigueHeader, gridRiskHeader, gridEnvHeader, gridHeartrateHeader, gridHeartrateVariablilityHeader, gridHydrationProfile, gridActivityProfile, gridBloodpressureProfile, gridFatigueProfile, gridHeartrateProfile, gridHydrationHeader, gridManDownHeader, gridManDownProfile, gridOxygenHeader, gridOxygenProfile, gridTemperatureProfile, valueAccess } from '../data/dummy';

import './Zackat.css';
import profile from '../data/profile.png';
import Bar from './Charts/Bar';

const Associates = () => {
  const usr = JSON.parse(localStorage.getItem('user'));
  const selectedOrg = JSON.parse(localStorage.getItem('organization'));
  const [enableDetails, setDetails] = useState(true);
  const [enableEvents, setEvents] = useState(true);
  const [limit, setLimit] = useState(1000);
  const navigate = useNavigate();
  const currentThemeColor = localStorage.getItem('colorMode');
  // const selectionSettings = { mode: 'Row', type: 'Single' };
  const details = { text: 'Location', tooltipText: 'Associate Location', prefixIcon: 'e-expand', id: 'location', disabled: enableDetails };
  const events = { text: 'Events', tooltipText: 'Associate Events', prefixIcon: 'e-justify', id: 'events', disabled: enableEvents };
  const alerts = { text: 'Alerts', tooltipText: 'Clear Alerts', prefixIcon: 'e-cut', id: 'alerts', disabled: enableEvents };
  const invite = { text: 'Invite', tooltipText: 'MobileApp Invitation', prefixIcon: 'e-resize', id: 'invite', disabled: enableDetails };

  const toolbarOptions = ['Search', 'Add', 'Edit', 'Delete', details, events, alerts, invite, 'PdfExport', 'ExcelExport'];
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [colDisplay, setColDisplay] = useState(false);
  const [sortOptions, setSortColumns] = useState({});
  const [groups, setGroups] = useState([]);
  let chartInstance;
  const primaryxAxis = { minimum: 0, maximum: 11, interval: 1, title: 'Risk' };
  const primaryyAxis = {
    title: 'Percentage',
    minimum: 0,
    maximum: 100,
    labelFormat: '{value}%',
  };
  const tooltip = {
    enable: true,
    fill: '#F6821E',
    border: {
      width: 2,
      color: '#366976',
    },
  };
  const titlestyle = {
    color: '#366976',
  };
  const bardata = [
    { x: 1, y: 28 },
  ];
  let grid;
  const searchThis = (args) => {
    if (grid) {
      const search = document.getElementById('associatesearch');
      if (search) {
        grid.search(search.value);
      }
    }
  };
  const onToolbarChange = (args) => {
    if (grid) {
      const selectedRow = grid.getSelectedRecords()[0];
      if (args.target.textContent === 'Update') {
        grid.endEdit();
      }
      if (args.target.textContent === 'Edit') {
        grid.startEdit();
      }
      if (args.target.textContent === 'Delete') {
        grid.deleteRecord(selectedRow);
      }
      if (args.target.textContent === 'Add') {
        grid.addRecord();
      }
      if (args.target.textContent === 'Events') {
        navigate(`../events?id=${selectedRow._id}&lat=${selectedRow.latlng.latitude}&lng=${selectedRow.latlng.longitude}&name=${selectedRow.name}`);
      }
      if (args.target.textContent === 'Location') {
        navigate(`../basic?id=${selectedRow._id}&lat=${selectedRow.latlng.latitude}&lng=${selectedRow.latlng.longitude}&name=${selectedRow.name}&tabNo=1`);
      }
      if (args.target.textContent === 'PDF Export') {
        const properties = {
          fileName: `PDFExport_Associates_${new Date().toISOString()}.pdf`,
          pageOrientation: 'Landscape',
        };
        grid.pdfExport(properties);
      }
      if (args.target.textContent === 'Excel Export') {
        const properties = {
          fileName: `ExcelExport_Associates_${new Date().toISOString()}.xlsx`,
        };
        grid.excelExport(properties);
      }
    }
  };
  const actionData = [
    { text: 'Not specified', value: 'na' },
    { text: 'FOTA', value: 'fota' },
    { text: 'Locate', value: 'locate' },
    { text: 'Reset', value: 'reset' },
    { text: 'SMS', value: 'sms' },
    { text: 'Rehome', value: 'rehome' },
  ];
  const [selectedOption, setSelectedOption] = useState(actionData[0]);
  const actionComponent = () => (
    <DropDownListComponent id="ddlelement" dataSource={actionData} placeholder="Device Action" value={selectedOption} onChange={smsDevice} />
  );

  const audioData = [
    { text: 'Not specified', value: 'na' },
  ];
  const [selectedAudioOption, setSelectedAudioOption] = useState(audioData[0]);
  const audioComponent = () => (
    <DropDownListComponent dataSource={audioData} placeholder="Send Recording" value={selectedAudioOption} onChange={recordingDevice} />
  );

  const biometricsComponent = () => (
    <div>
      <label htmlFor="showDisplay" style={{ padding: '10px 40px 10px 0' }}>
        Show Biometrics
      </label>
      <SwitchComponent id="showDisplay" checked={colDisplay} cssClass="bar-color handle-color" onChange={changeColDisplay} />
    </div>
  );
  const limitComponent = () => (
    <div style={{ float: 'right' }}>
      <label htmlFor="showLimit" style={{ padding: '10px 40px 10px 0' }}>
        Associate Limit
      </label>
      <input style={{ border: '2px solid black', backgroundColor: '#F6821E' }} type="text" defaultValue={limit} onChange={handleChangeLimit} />
    </div>
  );
  const inputStyle = { width: '300px', display: 'inline-block', float: 'right' };

  const searchComponent = () => (
    <div className="pt-2 relative mx-auto text-gray-600">
      <input
        className="border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none searchtext"
        type="search"
        name="search"
        style={inputStyle}
        id="associatesearch"
        placeholder="Search"
      />
      <button type="button" className="absolute right-0 top-0 mt-5 mr-4" onClick={searchThis}>
        <svg
          className="text-gray-600 h-4 w-4 fill-current"
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          id="Capa_1"
          x="0px"
          y="0px"
          viewBox="0 0 56.966 56.966"
          xmlSpace="preserve"
          width="512px"
          height="512px"
        >
          <path
            d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"
          />
        </svg>
      </button>
    </div>
  );

  const toolbarTemplate = () => (
    <div id="container">
      <ToolbarComponent id="toolbar" onClick={onToolbarChange}>
        <ItemsDirective>
          <ItemDirective text="Add" prefixIcon="e-add" />
          <ItemDirective text="Edit" disabled={enableDetails} prefixIcon="e-edit" />
          <ItemDirective text="Delete" disabled={enableDetails} prefixIcon="e-delete" />
          <ItemDirective type="Separator" />
          <ItemDirective text="Events" prefixIcon="e-justify" disabled={enableDetails} />
          <ItemDirective text="Location" prefixIcon="e-expand" disabled={enableDetails} />
          <ItemDirective type="Separator" />
          <ItemDirective template={actionComponent} disabled={enableDetails} />
          <ItemDirective type="Separator" />
          <ItemDirective template={audioComponent} disabled={enableDetails} />
          <ItemDirective type="Separator" />
          <ItemDirective text="PDF Export" prefixIcon="e-export-pdf" />
          <ItemDirective text="Excel Export" prefixIcon="e-export-excel" />
          <ItemDirective type="Separator" />
          <ItemDirective text="Search" template={searchComponent} disabled={enableDetails} style={{ float: 'right !important' }} />
          <ItemDirective type="Separator" />
        </ItemsDirective>
      </ToolbarComponent>
    </div>
  );
  const langData = [
    { value: 'English', id: 'en-US' },
    { value: 'German', id: 'de-DE' },
    { value: 'French', id: 'fr-FR' },
    { value: 'French (Canadian)', id: 'fr-CA' },
    { value: 'Spanish (US)', id: 'es-US' },
    { value: 'Spanish', id: 'es-ES' },
    { value: 'Portugese (Brazil)', id: 'pt-BR' },
    { value: 'Portugese (Portugal)', id: 'pt-PT' },
    { value: 'Danish', id: 'da-DK' },
    { value: 'Dutch', id: 'nl-NL' },
    { value: 'Austrailian', id: 'en-AU' },
    { value: 'English (British)', id: 'en-GB' },
    { value: 'English (India)', id: 'en-IN' },
    { value: 'English (Welsh)', id: 'en-GB-WLS' },
    { value: 'Icelandic', id: 'is-IS' },
    { value: 'Italian', id: 'it-IT' },
    { value: 'Japnense', id: 'ja-JP' },
    { value: 'Korean', id: 'ko-KR' },
    { value: 'Norweigan', id: 'nb-NO' },
    { value: 'Polish', id: 'pl-PL' },
    { value: 'Romanian', id: 'ro-RO' },
    { value: 'Russian', id: 'ru-RU' },
    { value: 'Swedish', id: 'sv-SE' },
    { value: 'Turkish', id: 'tr-TR' },
    { value: 'Welsh', id: 'cy-GB' },
    { value: 'Chinese (Simplified)', id: 'zh-CN' },
    { value: 'Chinese (Traditional)', id: 'zh-TW' },
  ];

  const [reload, setReload] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hideDialog, setDialog] = useState(false);
  const handleClick = () => {
    setDialog(!hideDialog);
  };
  const dialogClose = () => {
    setDialog(false);
  };
  const animationSettings = { effect: 'FlipYLeft' };
  const dbuttons = [
    {
      // Click the footer buttons to hide the Dialog
      click: () => {
        handleClick();
      },
      // Accessing button component properties by buttonModel property
      buttonModel: {
        // Enables the primary button
        isPrimary: true,
        content: 'OK',
      },
    },
  ];
  function rowDataBound(args) {
    if (args.row) {
      if (getValue('risk', args.data) > 80 || args.data.temperature.reading > 100) {
        args.row.classList.add('rowcellred');
      } else if (getValue('risk', args.data) > 50) {
        args.row.classList.add('rowcellylw');
      } else {
        args.row.classList.add('rowcellgrn');
      }
    }
  }
  useEffect(() => {
    // limit associates to the overall selection
    if (selectedOrg && selectedOrg.id && selectedOrg.id.length > 0) {
      usr.organizationid = selectedOrg.id;
    }
    usr.limit = limit;
    // fetch data
    const dataFetch = async () => {
      const query = Object.entries(usr)
        .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
        .join('&');
      const url = `${REACT_APP_API_ENDPOINT}/associates?${query}`;
      const mydata = await (
        await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
        })
      ).json();

      // set state when the data received
      if (mydata.user && mydata.user.preferences && mydata.user.preferences.associates) {
        if (mydata.user.preferences.associates.group) {
          const grp = mydata.user.preferences.associates.group;
          setGroups(grp);
        } else {
          setGroups(['organizationid']);
        }
        if (mydata.user.preferences.associates.biometrics) {
          const b = mydata.user.preferences.associates.biometrics;
          setColDisplay(b);
        }
        if (mydata.user.preferences.associates.sort && mydata.user.preferences.associates.sort[0].direction) {
          const mysortedColumns = { columns: mydata.user.preferences.associates.sort };
          setSortColumns(mysortedColumns);
        }
        if (mydata.user.preferences.associates.sort && mydata.user.preferences.associates.limit) {
          setLimit(mydata.user.preferences.associates.limit);
        }
      } else {
        setGroups(['organizationid']);
      }
      setData(mydata);
      setLoading(false);
    };
    dataFetch();
  }, [reload, limit]);

  const { height, width } = useWindowDimensions();
  const { activeMenu } = useStateContext();
  function formatPhoneNumber(phoneNumberString) {
    if (phoneNumberString.length <= 0) {
      return null;
    }
    if (phoneNumberString.length === 10) {
      phoneNumberString = `1${phoneNumberString}`;
    }
    const cleaned = (`${phoneNumberString}`).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }
  const gridOrgHeader = () => (
    <div className="">
      <img
        className="w-10 h-10"
        src={orgpng}
        alt="organization"
      />
      <pre>Organization</pre>
    </div>
  );

  const gridLangHeader = () => (
    <div className="">
      <img
        className="w-10 h-10"
        src={lngpng}
        alt="language"
      />
      <pre>Language</pre>
    </div>
  );
  const groupOptions = {
    enableLazyLoading: true,
    columns: groups,
  };
  const {
    screenSize,
    setScreenSize,
  } = useStateContext();
  const [file, setFile] = useState();
  const orgData = [];
  const langParams = {
    params: {
      actionComplete: () => false,
      dataSource: new DataManager(langData),
      fields: { value: 'id', text: 'value' },
      query: new Query(),
    },
  };
  const teamParams = {
    params: {
      actionComplete: () => false,
      allowFiltering: true,
      dataSource: new DataManager([
        { TeamDescr: 'Team 1', id: '1' },
        { TeamDescr: 'Team 2', id: '2' },
        { TeamDescr: 'Team 3', id: '3' },
      ]),
      fields: { text: 'TeamDescr', value: 'id' },
      query: new Query(),
    },
  };
  const actionParams = {
    params: {
      actionComplete: () => false,
      dataSource: new DataManager(actionData),
      fields: { value: 'id', text: 'value' },
      query: new Query(),
    },
  };
  function handleChange(e) {
    let upData = null;
    for (let idx = 0; idx < data.associates.length; idx += 1) {
      if (data.associates[idx]._id === e.target.id) {
        upData = data.associates[idx];
        break;
      }
    }
    const filedata = new FormData();
    const imagedata = e.target.files[0];
    filedata.append('userfile', imagedata);
    if (upData !== null) {
      filedata.append('_id', upData._id);
      filedata.append('fileName', e.target.files[0].name);
    } else {
      return;
    }
    fetch(`${REACT_APP_API_ENDPOINT}/fileup`, {
      method: 'POST',
      headers: {
        Authorization: `JWTToken ${usr.token}`,
      },
      body: filedata,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // window.location.reload();
        setReload(!reload);
      })
      .catch((error) => {
        alert('An error has occured during Insert!');
      });
  }
  function removePhoto(e) {
    let removeData = null;
    for (let idx = 0; idx < data.associates.length; idx += 1) {
      if (data.associates[idx]._id === e.target.id) {
        removeData = data.associates[idx];
        break;
      }
    }
    if (removeData == null || confirm(`Remove photo for ${removeData.name}?`) === false) {
      return;
    }
    if (removeData !== null) {
      fetch(`${REACT_APP_API_ENDPOINT}/fileup`, {
        method: 'DELETE',
        headers: {
          Authorization: `JWTToken ${usr.token}`,
        },
        body: JSON.stringify(removeData),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          setReload(!reload);
          // window.location.reload();
        })
        .catch((error) => {
          alert('An error has occured during Removal!');
        });
    }
  }
  const gridAssociateProfile = (props) => (
    <div className={`flex items-center gap-2 center 
      ${((props.activity && props.activity.alert && props.activity.alert.length > 0)
      || (props.heartrate && props.heartrate.alert && props.heartrate.alert.length > 0)
      || (props.bloodpressure && props.bloodpressure.alert && props.bloodpressure.length > 0)
      || (props.hydration && props.hydration.alert && props.hydration.alert.length > 0)
      || (props.temperature && props.temperature.alert && props.temperature.alert.length > 0)
      || (props.oxygen && props.oxygen.alert && props.oxygen.alert.length > 0)
      || (props.hrv && props.hrv.alert && props.hrv.alert.length > 0)) ? 'rowcellred' : 'rowcellgrn'}`}
    >
      {!props.employeeImage
        ? <input type="file" name="userfile" id={props._id} onChange={handleChange} />
        : (
          <div>
            <img
              className="rounded-full w-10 h-10"
              src={props.employeeImage ? props.employeeImage : profile}
              alt="employee"
            />
            <font color="black"><input type="submit" name="remove" id={props._id} value="&#x007F;" onClick={removePhoto} /></font>
            <br /><Link to={`tel:${props.mobile}`} style={{ textDecoration: 'underline !important' }}>{formatPhoneNumber(props.mobile)}</Link>{/* backgroundColor: 'white', color: '#F6821E', */}
          </div>
        )}
    </div>
  );
  const gridImageHeader = () => (
    <div className="">
      <img
        className="w-10 h-10"
        src={profile}
      />
      <pre>Image</pre>
    </div>
  );
  let crudUpdate = false;
  let crudCreate = false;
  let crudDelete = false;
  let myScreenSize = 'auto';
  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function customiseCell(args) {
    if (args.column.field === 'risk') {
      let tflag = '';
      let oflag = '';
      let hflag = '';
      let bpflag = '';
      let hrflag = '';
      let hrvflag = '';
      if (args.data.temperature && args.data.temperature.alert && args.data.temperature.alert !== '') {
        tflag = '<font color="red">&#10003;</font>';
      }
      if (args.data.heartrate && args.data.heartrate.alert && args.data.heartrate.alert !== '') {
        hrflag = '<font color="red">&#10003;</font>';
      }
      if (args.data.bloodpressure && args.data.bloodpressure.alert && args.data.bloodpressure.alert !== '') {
        bpflag = '<font color="red">&#10003;</font>';
      }
      if (args.data.oxygen && args.data.oxygen.alert && args.data.oxygen.alert !== '') {
        oflag = '<font color="red">&#10003;</font>';
      }
      if (args.data.hydration && args.data.hydration.alert && args.data.hydration.alert !== '') {
        hflag = '<font color="red">&#10003;</font>';
      }
      if (args.data.hrv && args.data.hrv.alert && args.data.hrv.alert !== '') {
        hrvflag = '<font color="red">&#10003;</font>';
      }
      const thistooltip = new Tooltip({
        width: '400px',
        height: '200px',
        // <img src=${heartrate} style="display: inline-block;" width="30px" height="30px" />
        // ${args.data.bloodpressure ? args.data.bloodpressure.reading : ''}<sub class="supsm">sys/dia</sub>${bpflag}
        content: `<h1 style="text-align: center;">${args.data.name ? args.data.name.toUpperCase() : ''}: BIOMETRICS</h1><table class="biotable"><tr><td class="biometricsgrn"><sup class="supsm">hr</sup>${args.data.heartrate ? args.data.heartrate.reading : ''}<sub class="supsm">bpm</sub>${hrflag}</td><td class="biometricsltgrn">
        <sup class="supsm">hrv</sup>${args.data.hrv ? args.data.hrv.reading : ''}<sub class="supsm">ms</sub>${hrvflag}</td></tr><tr><td class="biometricsorn"><sub class="supsm">O<sup  class="supsm">2</sup></sub>${args.data.oxygen ? args.data.oxygen.reading : ''}<sub class="supsm">%</sub>${oflag}</td><td class="biometricsylw"><sup class="supsm">temp</sup>${args.data.temperature ? args.data.temperature.reading : ''}<sub class="supsm">°F</sub>${tflag}</td></tr><tr><td class="biometricsblue"><sup class="supsm">hyd</sup>${args.data.hydration ? args.data.hydration.reading : ''}<sub class="supsm">%</sub>${hflag}</td><td class="biometricscyn"></td></tr></table>`,
      });
      thistooltip.appendTo(args.cell);
    }
    // const temp = args.data.temperature.reading;
    // const hydration = args.data.hydration.reading;
    let set = false;
    if (args.data.risk < 25) {
      // args.cell.classList.add('riskbelow-30');
      args.cell.classList.add('rowcellgrn');
    } else if (args.data.risk < 50) {
      // args.cell.classList.add('riskbelow-80');
      args.cell.classList.add('rowcellylw');
      set = true;
    } else if (args.data.risk < 80) {
      args.cell.classList.add('rowcellorn');
      set = true;
    } else {
      // args.cell.classList.add('riskabove-80');
      args.cell.classList.add('rowcellred');
      set = true;
    }
    if (!set && args.data.environment < 25) {
      // args.cell.classList.add('riskbelow-30');
      args.cell.classList.add('rowcellgrn');
    } else if (!set && args.data.environment < 50) {
      // args.cell.classList.add('riskbelow-80');
      args.cell.classList.add('rowcellylw');
    } else if (!set && args.data.environment < 80) {
      args.cell.classList.add('rowcellorn');
    } else if (!set) {
      // args.cell.classList.add('riskabove-80');
      args.cell.classList.add('rowcellred');
    }
    /*
    switch (args.column.index) {
      case 1:
        args.cell.classList.add('rowcellgrn');
        break;
      case 2:
        args.cell.classList.add('rowcellgrn');
        break;
      case 3:
        if (temp >= 101) {
          // args.cell.classList.add('below-30');
          args.cell.classList.add('rowcellgrn');
        } else if (temp > 99 && temp < 101) {
          // args.cell.classList.add('below-80');
          args.cell.classList.add('rowcellorn');
        }
        break;
      case 6:
        if (hydration <= 30) {
          args.cell.classList.add('rowcellred');
          // args.cell.classList.add('below-30');
        } else if (hydration > 30 && hydration < 70) {
          // args.cell.classList.add('below-80');
          args.cell.classList.add('rowcellylw');
        } else {
          // args.cell.classList.add('above-80');
          args.cell.classList.add('rowcellgrn');
        }
        break;
      default:
        args.cell.classList.add('rowcellgrn');
        break;
    }
    */
  }
  function recordingDevice(e) {
    if (!grid) {
      return;
    }
    if (e.target.itemData.value === 'na') {
      return;
    }
    const selectedRecords = grid.getSelectedRecords();
    if (!selectedRecords || selectedRecords.length === 0) {
      return;
    }
    const b = selectedRecords[0];
    if (!confirm(`Send recording ${e.target.itemData.text} to ${b.name}?`)) {
      return;
    }
    const message = `"audio": "${e.target.itemData.value}"`;
    const payload = { inventory: b, message };
    fetch(`${REACT_APP_API_ENDPOINT}/jasper`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWTToken ${usr.token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // The added/edited data will be saved in the Grid
        alert('audio request sent');
        setSelectedAudioOption('na');
      })
      .catch((error) => {
        console.error(error);
      });
  }
  function smsDevice(e) {
    if (!grid) {
      return;
    }
    if (!e) {
      return;
    }
    const selectedRecords = grid.getSelectedRecords();
    if (!selectedRecords || selectedRecords.length === 0) {
      return;
    }
    const b = selectedRecords[0];
    const cmd = e.target.itemData.value;
    let message = '';
    if (cmd == 'na') {
      return;
    }
    if (cmd === 'reset') {
      message = prompt(`Restart device ${b.device} (${b.name}) or put device to sleep?`, 'restart');
      if (!message || message.length === 0) {
        return;
      }
      if (message.toLocaleLowerCase() === 'restart') {
        message = '"reset":"restart"';
      } else if (message.toLocaleLowerCase() === 'sleep') {
        message = '"reset":"sleep"';
      } else {
        return;
      }
    } else if (cmd === 'fota') {
      if (!confirm(`Send a FOTA request to ${b.name}`)) {
        return;
      }
      message = '"fota":"ind"';
    } else if (cmd === 'locate') {
      message = '"locate":"query"';
      if (!confirm(`Send a LOCATE request to ${b.name}`)) {
        return;
      }
    } else if (cmd === 'rehome') {
      message = prompt(`ReHome device ${b.device} (${b.name}) production, staging or development?`, 'production');
      if (!message || message.length === 0) {
        return;
      }
      switch (message.toLocaleLowerCase()) {
        case 'production':
          message = '"srvr":{"url":"api.zackat.com", "port": "10900"}';
          break;
        case 'staging':
          message = '"srvr":{"url":"api.zackatlabs.com", "port": "10900"}';
          break;
        case 'development':
          message = '"srvr":{"url":"api.zackat.com", "port": "10900"}';
          break;
        default:
          return;
      }
    } else {
      message = prompt(`What SMS would you like to send to ${b.device} (${b.name})?`, '');
    }
    if (!message || message.length === 0) {
      return;
    }
    const payload = { inventory: b, message };
    fetch(`${REACT_APP_API_ENDPOINT}/jasper`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWTToken ${usr.token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        alert(`${cmd} request sent`);
        setSelectedOption('na');
        // The added/edited data will be saved in the Grid
      })
      .catch((error) => {
        console.error(error);
      });
  }
  const dialogContent = () => (
    <form>
      <div className="form-group">
        <label>Action:</label>
        <input type="text" className="form-control" id="action" />
        {/* <DropDownListComponent id="ddlelement" dataSource={actionData} placeholder="Select a Device Action" onChange={smsDevice()} /> */}
      </div>
    </form>
  );
  const actionComplete = (args) => {
    const org = JSON.parse(localStorage.getItem('organization'));
    /** Set initial Focus */
    if (args.requestType === 'beginEdit') {
      // args.form.elements.namedItem('name').focus();
      // eslint-disable-next-line prefer-destructuring
      const dialog = args.dialog;
      if (dialog && args.rowData && args.rowData.name) {
        dialog.header = `Details of ${args.rowData.name}`;
      }
    } else if (args.requestType === 'add') {
      args.form.elements.namedItem('name').focus();
    } else if (args.requestType === 'save' && args.form && args.action === 'add') {
      if (args.data && args.data.name.length > 0) {
        // The default edit operation is cancelled
        // Here you can send the updated data to your server using AJAX call
        if (!args.data.organizationid || args.data.organizationid.length === 0) {
          args.data.organizationid = org.id;
          args.data.organization = org.name;
        }
        fetch(`${REACT_APP_API_ENDPOINT}/associates`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(args.data),
        })
          .then((response) => response.json())
          .then((responseJson) => {
            // The added/edited data will be saved in the Grid
            if (responseJson && responseJson._id) {
              args.data._id = responseJson._id;
            }
            if (responseJson && responseJson.response && responseJson.response !== 'OK') {
              alert(responseJson.response);
              args.cancel = true;
            }
          })
          .catch((error) => {
            alert('An error has occured during Insert!');
            args.cancel = true;
          });
      }
    } else if (args.requestType === 'save' && args.form && args.action === 'edit') {
      if (args.data) {
        // Here you can send the updated data to your server using AJAX call
        orgData.forEach((element) => {
          if (element.id === args.data.organizationid) {
            args.data.organizationid = element.id;
            args.data.organization = element.value;
          }
        });
        fetch(`${REACT_APP_API_ENDPOINT}/associates`, {
          method: 'UPDATE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(args.data),
        })
          .then((response) => response.json())
          .then(() => {
            // The added/edited data will be saved in the Grid
            // The default edit operation is cancelled
          })
          .catch((error) => {
            alert('An error has occured during Update!');
          });
      }
    } else if (args.requestType === 'delete') {
      let entry = ' this entry?';
      if (args.data.length > 1) {
        entry = ' these entries?';
      }
      if (args.data) {
        // Here you can send the updated data to your server using AJAX call
        fetch(`${REACT_APP_API_ENDPOINT}/associates`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(args.data),
        })
          .then((response) => response.json())
          .then(() => {
            // The added/edited data will be saved in the Grid
            // The default edit operation is cancelled
          })
          .catch((error) => {
            alert('An error has occured during Delete!');
          });
      }
    }
  };
  function setUserPreferences(pref, val) {
    if (!data.user.preferences) {
      data.user.preferences = {};
    }
    if (!data.user.preferences.associates) {
      data.user.preferences.associates = {};
    }
    if (pref === 'group') {
      data.user.preferences.associates = { group: val, biometrics: data.user.preferences.associates.biometrics, sort: data.user.preferences.associates.sort, limit: Number(limit) };
    } else if (pref === 'biometrics') {
      data.user.preferences.associates = { group: data.user.preferences.associates.group, biometrics: val, sort: data.user.preferences.associates.sort, limit: Number(limit) };
    } else if (pref === 'sort') {
      data.user.preferences.associates = { group: data.user.preferences.associates.group, biometrics: data.user.preferences.associates.biometrics, sort: val, limit: Number(limit) };
    } else if (pref === 'limit') {
      data.user.preferences.associates = { group: data.user.preferences.associates.group, biometrics: data.user.preferences.associates.biometrics, sort: data.user.preferences.associates.sort, limit: Number(val) };
    }
    // Remove the New Password to avoid creating a new password
    data.user.newPassword = '';
    // Avoid Password comparison
    data.user.password = '';
    fetch(`${REACT_APP_API_ENDPOINT}/user`, {
      method: 'UPDATE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWTToken ${usr.token}`,
      },
      body: JSON.stringify(data.user),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson && responseJson.response && responseJson.response !== 'OK') {
          alert(responseJson.response);
        }
      })
      .catch((error) => {
        navigate('/login');
      });
  }
  function handleChangeLimit(e) {
    setLimit(e.target.value);
    setUserPreferences('limit', e.target.value);
  }
  function changeColDisplay() {
    setColDisplay(!colDisplay);
    setUserPreferences('biometrics', !colDisplay);
  }
  const actionBegin = (args) => {
    if (args.requestType === 'ungrouping' || args.requestType === 'grouping') {
      const index = groups.indexOf(args.columnName);
      if (index > -1 && args.requestType === 'ungrouping') { // only splice array when item is found
        groups.splice(index, 1); // 2nd parameter means remove one item only
      } else if (index === -1 && args.requestType === 'grouping') { // only splice array when item is found
        groups.push(args.columnName); // 2nd parameter means remove one item only
      }
      setUserPreferences('group', groups);
    } else if (args.requestType === 'sorting') {
      setUserPreferences('sort', [{ field: args.columnName, direction: args.direction }]);
    }
    if (!grid || !grid.columns) {
      return;
    }
    const cols = grid.columns;
    for (const col of cols) {
      if (col.headerText === 'Image' || col.headerText === 'Events' || col.headerText === 'Supervisor') {
        col.allowEditing = false;
      }
      if (col.headerText == 'Risk' || col.headerText == 'Environment') {
        col.visible = true;
      }
      if (args.requestType == 'add' && col.headerText === 'ID') {
        col.visible = false;
      }
      if (args.requestType == 'add' && col.headerText === 'Organization') {
        // col.visible = false;
      }
      if (args.requestType == 'filter' && (col.headerText == 'Image' || col.headerText == 'Name' || col.headerText == 'Device'
        || col.headerText == 'Organization' || col.headerText == 'Job Task')) {
        col.visible = true;
      } else if (args.requestType == 'filter' && (col.headerText == 'Temperature' || col.headerText == 'Bloodpressure'
        || col.headerText == 'Oxygen' || col.headerText == 'Heartrate' || col.headerText == 'HR Variability' || col.headerText == 'Hydration' || col.headerText == 'Fatigue'
        || col.headerText == 'Activity')) {
        col.visible = colDisplay;
      } else if (args.requestType == 'beginEdit') {
        if (col.headerText == 'Risk' || col.headerText == 'Environment' || col.headerText == 'Image' || col.headerText == 'Man Down' || col.headerText == 'Temperature' || col.headerText == 'Bloodpressure' || col.headerText == 'Oxygen' || col.headerText == 'Heartrate' || col.headerText == 'Hydration' || col.headerText == 'HR Variability' || col.headerText == 'Fatigue' || col.headerText == 'Activity' || col.headerText == 'ID') {
          col.visible = false;
        } else {
          col.visible = true;
        }
      } else if (args.requestType == 'add') {
        if (col.headerText == 'Risk' || col.headerText == 'Environment' || col.headerText == 'Man Down' || col.headerText == 'Temperature' || col.headerText == 'Bloodpressure' || col.headerText == 'Oxygen' || col.headerText == 'Heartrate' || col.headerText == 'HR Variability' || col.headerText == 'Hydration' || col.headerText == 'Fatigue' || col.headerText == 'Activity') {
          col.visible = false;
        } else {
          col.visible = true;
        }
      } else if (col.headerText == 'Image' || col.headerText == 'Name' || col.headerText == 'Device' || col.headerText == 'Temperature' || col.headerText == 'Bloodpressure'
        || col.headerText == 'Oxygen' || col.headerText == 'Heartrate' || col.headerText == 'HR Variability' || col.headerText == 'Hydration' || col.headerText == 'Fatigue'
        || col.headerText == 'Activity' || col.headerText == 'Man Down' || col.headerText == 'Organization' || col.headerText == 'Job Task' || col.headerText == 'Risk' || col.headerText == 'Environment') {
        col.visible = true;
      } else {
        col.visible = false;
      }
      if (col.headerText === 'Language') {
        col.visible = true;
      }
      if (col.headerText === 'Device') {
        if ((args.requestType === 'beginEdit' || args.requestType === 'add') && args.type === 'actionBegin') {
          col.visible = true;
        } else {
          col.visible = false;
        }
      }
      if (col.headerText === 'Job Task') {
        if ((args.requestType === 'beginEdit' || args.requestType === 'add') && args.type === 'actionBegin') {
          col.visible = true;
        } else {
          col.visible = false;
        }
      }
    }
  };
  function completeUpload(args) {
    alert('Your upload is complete!');
    setReload(!reload);
  }
  function rowClicked() {
    setDetails(false);
    setEvents(false);
  }
  let uploadObj;
  const path = {
    removeUrl: `${REACT_APP_API_ENDPOINT}/associatesremove`,
    saveUrl: `${REACT_APP_API_ENDPOINT}/associatesupload`,
  };
  const buttons = { browse: 'Choose File', clear: 'Clear All', upload: 'Upload All' };
  function onFileUpload(args) {
    // add addition data as key-value pair.
    // args.customFormData = [{ organizationid: data.user.organizationid }];
    args.customFormData = [{ organizationid: selectedOrg.id }];
  }
  const FilterOptions = {
    type: 'FilterBar',
    showFilterBarStatus: true,
    mode: 'Immediate',
  };
  /*
  const doubleClickHandler = (args) => {
    // grid.preventClick = true;
    navigate(`../basic?id=${args.rowData._id}&lat=${args.rowData.latlng.latitude}&lng=${args.rowData.latlng.longitude}`);
  };
  */
  const clickHandler = (args) => {
    if (grid && args.item.properties.text === 'Location') {
      const selectedRecords = grid.getSelectedRecords();
      if (!selectedRecords || selectedRecords.length === 0) {
        alert('Please select an associate');
      } else if (selectedRecords && selectedRecords.length === 1) {
        navigate(`../basic?id=${selectedRecords[0]._id}&lat=${selectedRecords[0].latlng.latitude}&lng=${selectedRecords[0].latlng.longitude}&name=${selectedRecords[0].name}&tabNo=1`);
      } else {
        alert('Please select only one associate');
      }
    } else if (grid && args.item.properties.text === 'Events') {
      const selectedRecords = grid.getSelectedRecords();
      if (!selectedRecords || selectedRecords.length === 0) {
        alert('Please select an associate');
      } else if (selectedRecords && selectedRecords.length === 1) {
        navigate(`../events?id=${selectedRecords[0]._id}&lat=${selectedRecords[0].latlng.latitude}&lng=${selectedRecords[0].latlng.longitude}&name=${selectedRecords[0].name}`);
      } else {
        alert('Please select only one associate');
      }
    } else if (grid && args.item.properties.text === 'Invite') {
      const selectedRecords = grid.getSelectedRecords();
      const recipient = prompt('Please enter the email address of the Associate that you would like to receive the invitation!', selectedRecords[0].email);
      if (recipient) {
        fetch(`${REACT_APP_API_ENDPOINT}/invite?associateid=${selectedRecords[0]._id}&recipient=${recipient}&email=${usr.email}`, {
          method: 'GET',
          headers: {
            Authorization: `JWTToken ${usr.token}`,
          },
        })
          .then((response) => response.json())
          .then((responseJson) => {
            if (responseJson && responseJson.response && responseJson.response !== 'OK') {
              alert(responseJson.response);
              args.cancel = true;
            }
          })
          .catch((error) => {
            alert('An error has occured during Invitation request!');
            args.cancel = true;
          });
      }
    } else if (grid && args.item.properties.text === 'Alerts') {
      const selectedRecords = grid.getSelectedRecords();
      if (!selectedRecords || selectedRecords.length === 0) {
        alert('Please select an associate');
      } else if (selectedRecords && selectedRecords.length === 1) {
        const updateAssociate = async () => {
          selectedRecords[0].activity.alert = '';
          selectedRecords[0].oxygen.alert = '';
          selectedRecords[0].heartrate.alert = '';
          selectedRecords[0].hrv.alert = '';
          selectedRecords[0].temperature.alert = '';
          selectedRecords[0].hydration.alert = '';
          selectedRecords[0].bloodpressure.alert = '';
          const newData = selectedRecords[0];
          const config = {
            Authorization: `JWTToken ${usr.token}`,
          };

          axios.put(
            `${REACT_APP_API_ENDPOINT}/associates`,
            newData,
            config,
          ).then((response) => {
            // Handle the response
            // window.location.reload();
            setReload(!reload);
            // data.associates = selectedRecords;
          })
            .catch((error) => {
              // Handle errors
            });
        };
        updateAssociate();
      } else {
        alert('Please select only one associate');
      }
    } else if (grid && args.item.properties.text === 'PDF Export') {
      const properties = {
        fileName: `PDFExport_Associates_${new Date().toISOString()}.pdf`,
        pageOrientation: 'Landscape',
      };
      grid.pdfExport(properties);
    } else if (grid && args.item.properties.text === 'Excel Export') {
      const properties = {
        fileName: `ExcelExport_Associates_${new Date().toISOString()}.xlsx`,
      };
      grid.excelExport(properties);
    }
  };
  if (activeMenu === false) {
    myScreenSize = 'auto';
  } else {
    const s = (screenSize - 450).toString();
    myScreenSize = `${s}px`;
  }
  /*
  if (!selectedOrg || !selectedOrg.id) {
    return <p>-- Please select and organization -- </p>;
  }
  */
  if (loading) {
    return <p>Loading... </p>;
  }
  if (data.role && data.role.associates) {
    if (data.role.associates.permissions.includes('Create')) {
      crudCreate = true;
    }
    if (data.role.associates.permissions.includes('Update')) {
      crudUpdate = true;
    }
    if (data.role.associates.permissions.includes('Delete')) {
      crudDelete = true;
    }
    if (!data.role.associates.permissions.includes('Read')) {
      return <p>Not authorized...</p>;
    }
  }
  const editOptions = {
    allowEditing: crudUpdate,
    allowAdding: crudCreate,
    allowDeleting: crudDelete,
    showConfirmDialog: true,
    showDeleteConfirmDialog: true,
    allowEditOnDblClick: true,
    mode: 'Dialog',
  };
  data.organization.forEach((org) => {
    orgData.push({ id: org._id, value: org.title });
  });
  if (data.recordings) {
    data.recordings.forEach((rec) => {
      const result = langData.find(({ id }) => id === rec.language);
      audioData.push({ value: rec._id, text: `${rec.name} (${result.value})` });
    });
  }

  const organizations = {
    params: {
      actionComplete: () => false,
      dataSource: new DataManager(data.organization),
      fields: { value: '_id', text: 'title' },
      query: new Query(),
    },
  };

  const valuerules = { required: true };
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl zackat-div">
      <Header category="" title="Associates" />
      <AMap dt={{ data }} />
      <div
        style={{
          backgroundColor: currentThemeColor,
          textAlign: 'right',
        }}
      >
        <div id="droparea">{crudCreate ? 'IMPORT' : ''}
          {crudCreate === true && (
            <UploaderComponent
              id="fileupload"
              type="file"
              ref={(uplaod) => { uploadObj = uplaod; }}
              asyncSettings={path}
              buttons={buttons}
              multiple={false}
              actionComplete={completeUpload}
              autoUpload={false}
              uploading={onFileUpload}
              allowedExtensions=".csv"
            />
          )}
        </div>
      </div>
      {/*
      <div className='control-section'>
        <MapsComponent id="maps" loaded={onMapsLoad.bind(this)} load={load} zoomSettings={{ enable: true, shouldZoomInitially: true }} legendSettings={{ visible: true }} titleSettings={{ text: 'Associate locations', textStyle: { size: '16px' } }}>
          <Inject services={[Marker, Legend, MapsTooltip, Zoom]} />
          <LayersDirective>
            <LayerDirective shapeData={worldMap} shapePropertyPath='continent' shapeDataPath='continent' shapeSettings={{ colorValuePath: 'color' }}>
              <MarkersDirective>
                <MarkerDirective visible={true} template='<div style="font-size: 12px;color:white;text-shadow: 0px 1px 1px black;font-weight: 500;width:50px">{{:name}}</div>' animationDuration={0} dataSource={markers} />
                <MarkerDirective visible={true} shape='Image' imageUrl={'https://maps.google.com/mapfiles/ms/icons/blue.png'} height={20} width={20} animationDuration={0} tooltipSettings={{ visible: true, valuePath: 'name' }} dataSource={markers} />
              </MarkersDirective>
            </LayerDirective>
          </LayersDirective>
        </MapsComponent>
      </div >
      recordDoubleClick={doubleClickHandler}

        */}
      {/*
      <div>
        <label htmlFor="showDisplay" style={{ padding: '10px 40px 10px 0' }}>
          Show Biometrics
        </label>
        <SwitchComponent id="showDisplay" checked={colDisplay} cssClass="bar-color handle-color" onChange={changeColDisplay} />
        <div style={{ float: 'right' }}>
          <label htmlFor="showLimit" style={{ padding: '10px 40px 10px 0' }}>
            Associate Limit
          </label>
          <input style={{ border: '2px solid black', backgroundColor: '#F6821E' }} type="text" defaultValue={limit} onChange={handleChangeLimit} />
        </div>
      </div>
        */ }
      {/* }
      <ChartComponent
        id="charts"
        ref={(chart) => { chartInstance = chart; }}
        primaryXAxis={primaryxAxis}
        tooltip={tooltip}
        titleStyle={titlestyle}
        isMultiSelect
        selectionMode="Point"
      >
        <Inject services={[BarSeries, Legend, Tooltip, DataLabel, Selection, Category]} />
        <SeriesCollectionDirective>
          <SeriesDirective dataSource={bardata} xName="x" yName="y" name="Risk Level" type="Bar" />
        </SeriesCollectionDirective>
      </ChartComponent>
        */}
      <div id="container">
        <ToolbarComponent id="toolbar" onClick={onToolbarChange}>
          <ItemsDirective>
            <ItemDirective template={biometricsComponent} />
            <ItemDirective type="Separator" />
            <ItemDirective template={actionComponent} disabled={enableDetails} />
            <ItemDirective type="Separator" />
            <ItemDirective template={audioComponent} disabled={enableDetails} />
            <ItemDirective type="Separator" />
            <ItemDirective template={limitComponent} align="right" />
          </ItemsDirective>
        </ToolbarComponent>
      </div>
      <GridComponent
        ref={(scope) => { grid = scope; }}
        id="assocGrid"
        dataSource={data.associates}
        width={myScreenSize}
        allowPaging
        allowSorting
        allowResizing
        pageSettings={{ pageSize: 20, pageCount: 5 }}
        editSettings={editOptions}
        filterSettings={FilterOptions}
        allowFiltering={data && data.associates && data.associates.length > 0}
        toolbar={toolbarOptions}
        // toolbarTemplate={toolbarTemplate}
        toolbarClick={clickHandler}
        sortSettings={sortOptions}
        actionComplete={actionComplete}
        actionBegin={actionBegin}
        queryCellInfo={customiseCell}
        allowGrouping
        groupSettings={groupOptions}
        allowTextWrap
        allowPdfExport
        allowExcelExport
        recordClick={rowClicked}
        // rowDataBound={rowDataBound}
        // enableVirtualization
        // enableColumnVirtualization
        // height="600"
      >
        <ColumnsDirective>
          <ColumnDirective
            field="employeeImage"
            headerText="Image"
            width="100"
            visible
            allowSorting={false}
            allowFiltering={false}
            template={gridAssociateProfile}
            headerTemplate={gridImageHeader}
          />
          <ColumnDirective
            field="name"
            headerText="Name"
            width="120"
            visible
            allowFiltering={false}
            textAlign="Left"
            headerTemplate={gridNameHeader}
          />
          <ColumnDirective
            field="risk"
            headerText="Risk"
            width="120"
            visible
            allowFiltering={false}
            textAlign="Left"
            headerTemplate={gridRiskHeader}
          />
          <ColumnDirective
            field="environment"
            headerText="Environment"
            width="120"
            visible
            allowFiltering={false}
            textAlign="Left"
            headerTemplate={gridEnvHeader}
          />
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {associatesGrid.map((item, index) => <ColumnDirective key={index} visible={colDisplay} {...item} />)}
          <ColumnDirective
            field="organizationid"
            headerText="Organization"
            width="150"
            textAlign="Left"
            editType="dropdownedit"
            headerTemplate={gridOrgHeader}
            validationRules={valuerules}
            visible
            foreignKeyField="_id"
            foreignKeyValue="title"
            dataSource={organizations.params.dataSource}
          />
          <ColumnDirective
            field="language"
            headerText="Language"
            width="150"
            visible
            textAlign="Left"
            editType="dropdownedit"
            headerTemplate={gridLangHeader}
            foreignKeyField="id"
            foreignKeyValue="value"
            dataSource={langParams.params.dataSource}
          />
          {colDisplay && (
            <ColumnDirective
              field="temperature.reading"
              headerText="Temperature"
              width="120"
              visible
              textAlign="Left"
              headerTemplate={gridTemperatureHeader}
              valueAccessor={valueAccess}
              template={gridTemperatureProfile}
            />
          )}
          {colDisplay && (
            <ColumnDirective
              field="bloodpressure.reading"
              headerText="Bloodpressure"
              width="120"
              visible={false}
              textAlign="Left"
              headerTemplate={gridBloodpressureHeader}
              valueAccessor={valueAccess}
              template={gridBloodpressureProfile}
            />
          )}
          {colDisplay && (
            <ColumnDirective
              field="oxygen.reading"
              headerText="Oxygen"
              width="120"
              visible={colDisplay}
              textAlign="Left"
              headerTemplate={gridOxygenHeader}
              valueAccessor={valueAccess}
              template={gridOxygenProfile}
            />
          )}
          {colDisplay && (
            <ColumnDirective
              field="hydration.reading"
              headerText="Hydration"
              width="120"
              visible={colDisplay}
              textAlign="Left"
              headerTemplate={gridHydrationHeader}
              valueAccessor={valueAccess}
              template={gridHydrationProfile}
            />
          )}
          {colDisplay && (
            <ColumnDirective
              field="heartrate.reading"
              headerText="Heartrate"
              width="120"
              visible={colDisplay}
              textAlign="Left"
              headerTemplate={gridHeartrateHeader}
              valueAccessor={valueAccess}
              template={gridHeartrateProfile}
            />
          )}
          {colDisplay && (
            <ColumnDirective
              field="hrv.reading"
              headerText="HR Variability"
              width="120"
              visible={colDisplay}
              textAlign="Left"
              headerTemplate={gridHeartrateVariablilityHeader}
              valueAccessor={valueAccess}
              template={gridHeartrateProfile}
            />
          )}
          {colDisplay && (
            <ColumnDirective
              field="activity.reading"
              headerText="Fatigue"
              width="120"
              visible={colDisplay}
              textAlign="Left"
              headerTemplate={gridFatigueHeader}
              valueAccessor={valueAccess}
              template={gridFatigueProfile}
            />
          )}
          {colDisplay && (
            <ColumnDirective
              field="activity.reading"
              headerText="Man Down"
              width="120"
              visible={colDisplay}
              textAlign="Left"
              headerTemplate={gridManDownHeader}
              valueAccessor={valueAccess}
              template={gridManDownProfile}
            />
          )}
          {colDisplay && (
            <ColumnDirective
              field="activity.reading"
              headerText="Activity"
              width="120"
              visible={colDisplay}
              textAlign="Left"
              headerTemplate={gridActivityHeader}
              valueAccessor={valueAccess}
              template={gridActivityProfile}
            />
          )}
        </ColumnsDirective>
        <Inject services={[Page, Edit, Toolbar, Filter, Sort, Resize, PdfExport, ExcelExport, ForeignKey, Group, LazyLoadGroup, VirtualScroll]} />
      </GridComponent>
    </div>
  );
};
export default Associates;
