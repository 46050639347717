/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import React, { useState, useEffect, useCallback } from 'react';
import { APIProvider, Map, InfoWindow, AdvancedMarker, Pin, useAdvancedMarkerRef } from '@vis.gl/react-google-maps';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import zackatPin from '../data/mappin.png';
import './Map.css';

const GMap = () => {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [refreshData, setRefreshData] = useState(true);
  const [searchParams] = useSearchParams();
  const associateid = searchParams.get('id');
  const centerlat = Number(searchParams.get('lat'));
  const centerlng = Number(searchParams.get('lng'));
  const name = String(searchParams.get('name'));

  const [position, setPosition] = useState({ lat: 0, lng: 0 });
  const [markers, setMarker] = useState([{ lat: centerlat, lng: centerlng, title: name, _id: 1, info: 'Last known location' }]);
  const [activeMarker, setActiveMarker] = useState(null);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const { REACT_APP_GOOGLE_MAPS_API_KEY } = process.env;
  const { REACT_APP_GOOGLE_MAPS_ID } = process.env;
  const usr = JSON.parse(localStorage.getItem('user'));
  const [infoWindowShown, setInfoWindowShown] = useState(false);
  const [markerRef, amarker] = useAdvancedMarkerRef();
  const handleActiveMarker = (marker) => {
    setInfoWindowShown((isShown) => !isShown);
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };
  let initialMarkers = [];
  const getData = async () => {
    // const response = await axios.get(`${REACT_APP_API_ENDPOINT}/events?associateid=${associateid}`);
    const response = await axios({
      url: `${REACT_APP_API_ENDPOINT}/events?associateid=${associateid}`,
      method: 'get',
      headers: {
        Authorization: `JWTToken ${usr.token}`,
        'Content-Type': 'application/json',
      },
    });
    setData(response.data);
    initialMarkers = [];
    if (response.data) {
      let index = 0;
      response.data.map((event) => {
        if (event && event.device && event.latlng && event.latlng.latitude && event.latlng.longitude && event.latlng.latitude !== 0 && event.latlng.longitude !== 0) {
          index += 1;
          const marker = {
            lat: event.latlng.latitude,
            lng: event.latlng.longitude,
            title: event.device,
            _id: event._id,
            index,
            info: `Timestamp: ${format(parseISO(event.timestamp), 'MM/dd/yy hh:mm')}`,
          };
          initialMarkers.push(marker);
        }
      });
    }
    setTimeout(getData, 30000);
    if (initialMarkers.length > 0) {
      setPosition({ lat: initialMarkers[0].lat, lng: initialMarkers[0].lng });
      setLoadingData(false);
      setMarker(initialMarkers);
    }
  };
  useEffect(() => {
    getData();
  }, [refreshData]);
  if (loadingData && !refreshData) {
    return <p>Loading... </p>;
  }
  // clicking the marker will toggle the infowindow
  const handleMarkerClick = useCallback(
    () => setInfoWindowShown((isShown) => !isShown),
    [],
  );
  // if the maps api closes the infowindow, we have to synchronize our state
  const handleClose = useCallback(() => setInfoWindowShown(false), []);
  let map;

  function onGoogleMapLoad() {
    map.fitBounds();
  }
  return (
    <APIProvider apiKey={`${REACT_APP_GOOGLE_MAPS_API_KEY}`}>
      <button
        className="button"
        type="button"
        onClick={() => setRefreshData(!refreshData)}
      >
        Refresh
      </button>
      <Map
        style={{ width: '100%', height: '100vh' }}
        defaultCenter={{ lat: centerlat, lng: centerlng }}
        mapId={`${REACT_APP_GOOGLE_MAPS_ID}`}
        defaultZoom={10}
        onClick={() => setActiveMarker(null)}
      >
        {/* <Marker position={{ lat: 33.049831, lng: -96.838485 }} /> */}
        {/* <AdvancedMarker places={markers} /> */}
        {markers.map((marker, index) => (
          <AdvancedMarker
            className="bounce"
            key={marker._id}
            ref={markerRef}
            draggable
            position={marker}
            onClick={() => handleActiveMarker(marker)}
          >
            <img src={zackatPin} width="60px" height="60px" />
            {/*
            <Pin
              background="#000000"
              borderColor="#ffffff"
              glyphColor="#ff0000"
              title={marker.title}
            />
            */}
            {infoWindowShown && (
              <InfoWindow
                position={activeMarker}
                onClose={handleClose}
              >
                <h2>Device: {activeMarker.title}</h2>
                <p>Info: {activeMarker.info}</p>
                <p>ID:{activeMarker._id}</p>
                <p>Index:{activeMarker.index}</p>

              </InfoWindow>
            )}
          </AdvancedMarker>

        ))}
      </Map>
    </APIProvider>

  );
};
export default GMap;
