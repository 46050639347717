/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-cycle */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
// import ReactDOM from 'react-dom';
import { KanbanComponent, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-react-kanban';
import { TextBoxComponent, UploaderComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent, SwitchComponent } from '@syncfusion/ej2-react-buttons';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { addClass } from '@syncfusion/ej2-base';
import { Query } from '@syncfusion/ej2-data';
import { inventoryGrid } from '../data/dummy';
import armband from '../data/watchsketch.png';
import { Header } from '../components';
// import { Methods } from './Methods';
import mappin from '../data/mappin.png';
import './inventory.css';
import './Zackat.css';

const Inventory = () => {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const valuerules = { required: true };
  // const { loading, data } = Methods('inventory', 'GET', '');
  const [loading, setLoading] = useState(true);
  const [refetch, setRefetch] = useState(false);
  const [data, setData] = useState([]);
  let orgObj;
  let kanbanObj;
  let priorityObj;
  let textBoxObj;
  const usr = JSON.parse(localStorage.getItem('user'));
  const organization = JSON.parse(localStorage.getItem('organization'));
  const [expanded, setExpanded] = useState(false);

  function setUserPreferences(pref, val) {
    if (!data.user.preferences) {
      data.user.preferences = {};
    }
    if (!data.user.preferences.card) {
      data.user.preferences.card = {};
    }
    if (pref === 'expanded') {
      data.user.preferences.card = { expanded: val };
    }
    // Remove the New Password to avoid creating a new password
    data.user.newPassword = '';
    // Avoid Password comparison
    data.user.password = '';
    fetch(`${REACT_APP_API_ENDPOINT}/user`, {
      method: 'UPDATE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWTToken ${usr.token}`,
      },
      body: JSON.stringify(data.user),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson && responseJson.response && responseJson.response !== 'OK') {
          alert(responseJson.response);
        }
      })
      .catch((error) => {
        alert('error setting user preferences');
      });
  }

  function changeExpanded() {
    setExpanded(!expanded);
    setUserPreferences('expanded', !expanded);
    const swimlaneHeaders = document.querySelectorAll('.e-swimlane-header .e-item-count');
    for (let i = 0; i < swimlaneHeaders.length; i += 1) {
      const swim = swimlaneHeaders[i].querySelector('.e-icons .e-swimlane-row-expand');
      if (swim) {
        // Not finding this, so we will click at the parent level
        swim.click();
      } else {
        swimlaneHeaders[i].click();
      }
    }
  }
  const orgData = [];
  useEffect(() => {
    const fetchData = async () => {
      setRefetch(false);
      try {
        usr.organizationid = organization.id;
        const query = Object.entries(usr)
          .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
          .join('&');
        const url = `${REACT_APP_API_ENDPOINT}/inventory?${query}`;
        const response = await axios({
          url,
          method: 'get',
          headers: {
            Authorization: `JWTToken ${usr.token}`,
            'Content-Type': 'application/json',
          },
        });
        if (response.data.user && response.data.user.preferences && response.data.user.preferences.card) {
          if (response.data.user.preferences.card.expanded) {
            setExpanded(response.data.user.preferences.card.expanded);
          } else {
            setExpanded(false);
          }
        } else {
          setExpanded(false);
        }
        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchData();
  }, [refetch]);

  if (loading) {
    return <p>Loading... </p>;
  }
  if (data.organization) {
    data.organization.forEach((element) => {
      orgData.push({ id: element._id, value: element.title });
    });
  }
  const organizations = {
    params: {
      actionComplete: () => false,
      allowFiltering: true,
      dataSource: data.organization,
      fields: { value: '_id', text: 'title' },
      query: new Query(),
    },
  };
  function changeOrganization(e) {
    let found = false;
    const invArray = [];
    if (confirm(`Change Organization to ${e.target.properties.text} ID: ${e.target.properties.value}?`)) {
      for (let i = 0; i < data.inventory.length; i += 1) {
        if (e.target.id === data.inventory[i]._id) {
          found = true;
          data.inventory[i].organizationid = e.target.properties.value;
          invArray.push(data.inventory[i]);
          break;
        }
      }
      if (found) {
        fetch(`${REACT_APP_API_ENDPOINT}/inventory`, {
          method: 'UPDATE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(invArray),
        })
          .then((response) => response.json())
          .then((responseJson) => {
            // The added/edited data will be saved in the Grid
            console.log('changeOrganization', responseJson.response);
          })
          .catch((error) => {
            console.error('error');
          });
      } else {
        console.log('put it back');
        window.location.reload();
        return false;
      }
    } else {
      console.log('put it back, they said no');
      window.location.reload();
      return false;
    }
    return true;
  }
  const actionComplete = (args) => {
    /** Set initial Focus */
    if (args.requestType === 'cardCreated') {
      if (args.addedRecords) {
        // The default edit operation is cancelled
        // Here you can send the updated data to your server using AJAX call
        const result = orgData.find(({ value }) => value === args.addedRecords[0].organization);
        if (!result) {
          args.addedRecords[0].organizationid = organization.id;
          args.addedRecords[0].organization = organization.name;
        } else {
          args.addedRecords[0].organizationid = result.id;
        }
        if (confirm(`Would you really like to add this entry for Organization ${args.addedRecords[0].organization}, with a Status of ${args.addedRecords[0].status}?`)) {
          args.addedRecords[0].simstatus = { status: args.addedRecords[0].status };
          if (args.addedRecords[0].device && args.addedRecords[0].device.length >= 19 && args.addedRecords[0].device.length <= 20) {
            if (isNaN(args.addedRecords[0].device)) {
              alert(`Invalid Device ID ${args.addedRecords[0].device}`);
              setRefetch(!refetch);
              return;
            }
          } else {
            alert(`Invalid Device ID ${args.addedRecords[0].device}`);
            setRefetch(!refetch);
            return;
          }
          fetch(`${REACT_APP_API_ENDPOINT}/inventory`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `JWTToken ${usr.token}`,
            },
            body: JSON.stringify(args.addedRecords),

          })
            .then((response) => response.json())
            .then((responseJson) => {
              // args.cancel = true;
            })
            .catch((error) => {
              console.error(error);
            });
        }
      }
    } else if (args.requestType === 'cardChanged') {
      if (args) {
        // The default edit operation is cancelled
        // Here you can send the updated data to your server using AJAX call
        fetch(`${REACT_APP_API_ENDPOINT}/inventory`, {
          method: 'UPDATE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(args.changedRecords),

        })
          .then((response) => response.json())
          .then((responseJson) => {
            // The added/edited data will be saved in the Grid
            args.cancel = false;
          })
          .catch((error) => {
            console.error('error');
          });
      }
    } else if (args.requestType === 'cardRemoved') {
      if (args.deletedRecords) {
        // The default edit operation is cancelled
        // Here you can send the updated data to your server using AJAX call
        fetch(`${REACT_APP_API_ENDPOINT}/inventory`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(args.deletedRecords),

        })
          .then((response) => response.json())
          .then((responseJson) => {
            // The added/edited data will be saved in the Grid
            // args.cancel = true;
          })
          .catch((error) => {
            console.error('error');
          });
      }
    }
  };

  function tryRefetching() {
    setRefetch(true);
  }
  function simStatusChange(b) {
    let msg = 'ACTIVATE';
    let state = 'ACTIVATED';
    if (b.simstatus.status === 'ACTIVATED' || b.simstatus.status === 'ACTIVATION_READY' || b.simstatus.status === 'TEST_READY') {
      msg = 'DEACTIVATE';
      state = 'DEACTIVATED';
    }

    if (confirm(`Would you like to ${msg} SIM ${b.device}?`)) {
      b.simstatus.status = state;
      fetch(`${REACT_APP_API_ENDPOINT}/inventory`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWTToken ${usr.token}`,
        },
        body: JSON.stringify(b),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          // The added/edited data will be saved in the Grid
          setTimeout(tryRefetching, 10000);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
  function getSimStatus(b) {
    // Set SimStatus to null to fetch from Jasper
    const j = { _id: b._id, simstatus: null };
    fetch(`${REACT_APP_API_ENDPOINT}/inventory`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWTToken ${usr.token}`,
      },
      body: JSON.stringify(b),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // The added/edited data will be saved in the Grid
        setTimeout(tryRefetching, 10000);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function smsDevice(b, cmd) {
    let message = '';
    if (cmd === 'reset') {
      message = prompt(`Restart device ${b.device} or put device to sleep?`, 'restart');
      if (message.toLocaleLowerCase() === 'restart') {
        message = '"reset":"restart"';
      } else if (message.toLocaleLowerCase() === 'sleep') {
        message = '"reset":"sleep"';
      } else {
        return;
      }
    } else if (cmd === 'fota') {
      message = '"fota":"ind"';
    } else if (cmd === 'locate') {
      message = '"locate":"query"';
    } else if (cmd === 'rehome') {
      message = prompt(`ReHome device ${b.device} production, staging or development?`, 'production');
      if (!message || message.length === 0) {
        return;
      }
      switch (message.toLocaleLowerCase()) {
        case 'production':
          message = '"srvr": {"url":"api.zackat.com", "port": "10900"}';
          break;
        case 'staging':
          message = '"srvr": {"url":"api.zackatlabs.com", "port": "10900"}';
          break;
        case 'development':
          message = '"srvr": {"url":"api.zackat.com", "port": "10900"}';
          break;
        default:
          return;
      }
    } else {
      message = prompt(`What SMS would you like to send to ${b.device}?`, '');
    }
    if (!message || message.length === 0) {
      return;
    }
    const payload = { inventory: b, message };
    fetch(`${REACT_APP_API_ENDPOINT}/jasper`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWTToken ${usr.token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // The added/edited data will be saved in the Grid
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function cardTemplate(props) {
    return (
      <div className="card-template">
        <div
          style={
            {
              border: '2px solid darkgrey',
            }
          }
          className="e-card-content e-card-separator"
        >
          <table className="card-template-wrap">
            <tbody>
              <tr>
                <td className="CardHeader">Associate:</td>
                <td><img
                  className="e-card-avatar"
                  src={props.resultField && props.resultField[0] ? props.resultField[0].employeeImage : 'https://monaco.zackat.com/blank.png'}
                  width="50"
                  height="50"
                />{props.resultField && props.resultField[0] ? props.resultField[0].name : 'unassigned'}
                </td>
              </tr>
              {props.resultField && props.resultField[0] && props.resultField[0].latlng && props.resultField[0].latlng.latitude !== 0 && props.resultField[0].latlng.longitude !== 0 && (
                <tr>
                  <td className="CardHeader">Location:</td>
                  <td>
                    <Link to={`/basic?id=${props.resultField[0]._id}&lat=${props.resultField[0].latlng.latitude}&lng=${props.resultField[0].latlng.longitude}&name=${props.resultField[0].name}&tabNo=3`}>
                      <img
                        className="w-8 h-8"
                        src={mappin}
                        alt="map"
                      />
                    </Link>
                  </td>
                </tr>
              )}
              <tr>
                <td className="CardHeader">Device:</td>
                <td>{props.device}</td>
              </tr>
              {props.simstatus && (
                <tr>
                  <td className="CardHeader">SIM Status:</td>
                  <td>
                    {props.simstatus.status === 'ACTIVATED' || props.simstatus.status === 'TEST_READY' || props.simstatus.status === 'ACTIVATION_READY' ? (
                      <ButtonComponent id="simstatus" className="e-btn e-success" onClick={() => simStatusChange(props)}>ACTIVE</ButtonComponent>
                    ) : (
                      <ButtonComponent id="simstatus" className="e-btn e-warning" onClick={() => simStatusChange(props)}>INACTIVE</ButtonComponent>
                    )}
                  </td>
                </tr>
              )}
              {props.simstatus && (
                <tr>
                  <td className="CardHeader">SMS:</td>
                  <td>
                    <ButtonComponent id="smsreset" className="e-btn e-danger" onClick={() => smsDevice(props, 'reset')}>Reset</ButtonComponent>
                    &nbsp;<ButtonComponent id="smsfota" className="e-btn e-success" onClick={() => smsDevice(props, 'fota')}>FOTA</ButtonComponent>
                    &nbsp;<ButtonComponent id="sms" className="e-btn e-info" onClick={() => smsDevice(props, 'rehome')}>ReHome</ButtonComponent>
                    &nbsp;<ButtonComponent id="sms" className="e-btn" onClick={() => smsDevice(props, '')}>SMS</ButtonComponent>

                  </td>
                </tr>
              )}
              {props.simstatus && (
                <tr>
                  <td className="CardHeader">Refresh:</td>
                  <td>
                    <ButtonComponent id="sms" className="e-btn e-link" onClick={() => getSimStatus(props)}>GET {props.simstatus.status ? (`(${props.simstatus.status})`) : ''}</ButtonComponent>
                  </td>
                </tr>
              )}
              <tr>
                <td className="CardHeader">IMEI:</td>
                <td>{props.imei && props.imei !== 'undefined' ? props.imei : ''}</td>
              </tr>
              <tr>
                <td className="CardHeader">MSN:</td>
                <td>{props.msn && props.msn !== 'undefined' ? props.msn : ''}</td>
              </tr>
              <tr>
                <td className="CardHeader">Version:</td>
                <td>{props.version && props.version !== 'undefined' ? props.version : ''}
                </td>
              </tr>
              <tr>
                <td className="CardHeader">Battery:</td>
                <td style={{
                  color: props.battery <= 60 ? 'red' // you may use your preferred color hexadecimal instead (ex: #f00)
                    : 'green', // you may use your preferred color hexadecimal instead (#00f)
                }}
                >{props.battery && props.battery !== 'undefined' ? props.battery : ''}%
                </td>
              </tr>
              <tr>
                <td className="CardHeader">RSRP:</td>
                <td style={{
                  // you may use your preferred color hexadecimal instead (ex: #f00)
                  // eslint-disable-next-line no-nested-ternary
                  color: props.rssi >= -90 ? 'green' : props.rssi >= -105 ? '#FFBF00' : props.rssi >= -120 ? 'orange' : 'red',
                }}
                > {props.rssi && props.rssi !== 'undefined' ? props.rssi : ''}dBm
                </td>
              </tr>
              <tr>
                <td className="CardHeader">Organization:</td>
                <td>
                  <DropDownListComponent ref={(orgs) => { orgObj = orgs; }} id={props._id} dataSource={data.organization} fields={organizations.params.fields} onChange={changeOrganization} value={props.organizationid} placeholder="Select an Organization" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  let crudUpdate = false;
  let crudCreate = false;
  let crudDelete = false;
  if (data.role && data.role.inventory && data.role.inventory.permissions.includes('Create')) {
    crudCreate = true;
  }
  if (data.role && data.role.inventory && data.role.inventory.permissions.includes('Update')) {
    crudUpdate = true;
  }
  if (data.role && data.role.inventory && data.role.inventory.permissions.includes('Delete')) {
    crudDelete = true;
  }
  if (data.role && data.role.inventory && !data.role.inventory.permissions.includes('Read')) {
    return <p>Not authorized...</p>;
  }
  const fields = [
    { text: 'Device', key: 'device', type: 'TextBox' },
    { text: 'Status', key: 'status', type: 'DropDown' },
    { text: 'Version', key: 'version', type: 'TextBox' },
    { text: 'RSRP', key: 'rssi', type: 'TextArea' },
    { text: 'IMEI', key: 'imei', type: 'TextBox' },
    { text: 'MSN', key: 'msn', type: 'TextBox' },
  ];
  function columnTemplate(props) {
    return (
      <div className="header-template-wrap">
        <div className={`header-icon e-icons ${props.keyField}`} />
        <div className="header-text">{props.headerText}</div>
      </div>
    );
  }

  function cardRendered(args) {
    const val = args.data.status;
    addClass([args.element], val);
  }
  function onFileUpload(args) {
    // add addition data as key-value pair.
    args.customFormData = [{ organizationid: data.user.organizationid }];
  }

  let uploadObj;
  const path = {
    removeUrl: `${REACT_APP_API_ENDPOINT}/inventoryremove`,
    saveUrl: `${REACT_APP_API_ENDPOINT}/inventoryupload`,
  };

  function searchClick(e) {
    const searchValue = e.value;
    let searchQuery = new Query();
    if (searchValue !== '') {
      searchQuery = new Query().search(searchValue, ['device', 'imei', 'msn'], 'contains', true);
    }
    kanbanObj.query = searchQuery;
  }

  function reset() {
    textBoxObj.value = '';
    kanbanObj.query = new Query();
  }
  const bindData = () => {
    const swimlaneHeaders = document.querySelectorAll('.e-swimlane-header .e-item-count');
    for (let i = 0; i < swimlaneHeaders.length; i += 1) {
      const swim = swimlaneHeaders[i].querySelector('.e-icons .e-swimlane-row-expand');
      if (swim) {
        // Not finding this, so we will click at the parent level
        swim.click();
      } else {
        swimlaneHeaders[i].click();
      }
    }
  };
  function change(args) {
    let filterQuery = new Query();
    if (args.value !== 'None') {
      if (args.element.id === 'version') {
        filterQuery = new Query().where('version', 'equal', args.value);
      }
    }
    kanbanObj.query = filterQuery;
  }
  const priorityData = ['None', '1.0', '1.1', '1.2', '1.3'];

  function completeUpload(args) {
    alert('Upload has completed');
  }
  const buttons = { browse: 'Choose File', clear: 'Clear All', upload: 'Upload All' };
  const currentThemeColor = localStorage.getItem('colorMode');
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl zackat-div">
      <img
        className="w-20 h-20"
        src={armband}
        alt="arm band"
      />
      <Header title="Inventory" />
      <div style={{
        backgroundColor: currentThemeColor,
        textAlign: 'right',
        marginTop: '-40px',
      }}
      >
        <div id="droparea">{crudCreate ? 'IMPORT' : ''}
          {crudCreate === true && (
            <UploaderComponent
              id="fileupload"
              type="file"
              ref={(uplaod) => { uploadObj = uplaod; }}
              asyncSettings={path}
              buttons={buttons}
              multiple={false}
              actionComplete={completeUpload}
              autoUpload={false}
              uploading={onFileUpload}
              allowedExtensions=".csv"
            />
          )}
        </div>
      </div>
      <div className="control-wrapper">
        <div>
          <div style={{ border: '1pxtip solid black', width: '100%' }}>
            <table>
              <tbody>
                <tr>
                  <td style={{ width: '200px' }}>
                    Search:<TextBoxComponent id="search" ref={(kanban) => { textBoxObj = kanban; }} showClearButton placeholder="Enter search text" input={searchClick} />
                  </td>
                  <td style={{ width: '200px' }}>
                    <ButtonComponent id="reset" className="e-btn" onClick={reset}>Reset</ButtonComponent>
                  </td>
                  <td style={{ width: '200px' }}>
                    Filter:<DropDownListComponent id="verion" ref={(kanbant) => { priorityObj = kanbant; }} dataSource={priorityData} change={change} placeholder="Select a version" />
                  </td>
                  <td style={{ width: '200px' }}>
                    <div>
                      <label htmlFor="showDisplay" style={{ padding: '10px 40px 10px 0' }}>
                        Collapse
                      </label>
                      <SwitchComponent id="showDisplay" checked={expanded} cssClass="bar-color handle-color" onChange={changeExpanded} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <KanbanComponent
          cssClass="kanban-overview"
          id="inventory"
          keyField="status"
          dataSource={data.inventory}
          actionComplete={actionComplete}
          dialogSettings={{ fields, className: 'e-dialog-delete' }}
          allowDragAndDrop
          swimlaneSettings={{ keyField: 'organization', allowDragAndDrop: true, sortDirection: 'Ascending' }}
          enablePersistence={false}
          enableVirtualization={false}
          dataBound={expanded ? bindData : null}
          ref={(kanban) => { kanbanObj = kanban; }}
          cardSettings={{
            headerField: 'device',
            contentField: 'imei',
            template: cardTemplate,
          }}
        >
          <ColumnsDirective>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {inventoryGrid.map((item, index) => (
              <ColumnDirective
                key={index}
                {...item}
                template={columnTemplate}
                showAddButton
              />
            ))}
          </ColumnsDirective>
        </KanbanComponent>
      </div>
    </div>
  );
};
export default Inventory;
