/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-restricted-syntax */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import React, { useRef, useState, useEffect } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Sort,
  Search,
  Filter,
  Page,
  Resize,
  Edit,
  Inject, Toolbar,
  ContextMenu,
  ForeignKey,
  LazyLoadGroup,
  Group,
  SortSettings,
} from '@syncfusion/ej2-react-grids';
import { useNavigate } from 'react-router-dom';
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import { Query } from '@syncfusion/ej2-data';

import { contextMenuItems, organizationsGrid } from '../data/dummy';
import { Header } from '../components';
import './Zackat.css';

const Organizations = () => {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const orgData = [];
  let grid = useRef(null);
  const valuerules = { required: true };
  const [enableJobs, setJobs] = useState(true);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const usr = JSON.parse(localStorage.getItem('user'));
  const selectedOrg = JSON.parse(localStorage.getItem('organization'));
  const [reload, setReload] = useState(true);
  const [groups, setGroups] = useState([]);
  const [sortOptions, setSortColumns] = useState({});

  function rowClicked() {
    setJobs(false);
  }
  useEffect(() => {
    // limit associates to the overall selection
    if (selectedOrg && selectedOrg.id && selectedOrg.id.length > 0) {
      usr.organizationid = selectedOrg.id;
    }
    // fetch data
    const dataFetch = async () => {
      const query = Object.entries(usr)
        .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
        .join('&');
      const url = `${REACT_APP_API_ENDPOINT}/organizations?${query}`;
      const mydata = await (
        await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
        })
      ).json();

      if (mydata.user && mydata.user.preferences && mydata.user.preferences.organizations) {
        if (mydata.user.preferences.organizations.group) {
          setGroups(mydata.user.preferences.organizations.group);
        } else {
          setGroups(['parent.name']);
        }
        if (mydata.user.preferences.organizations.sort && mydata.user.preferences.organizations.sort[0].direction) {
          const mysortedColumns = { columns: mydata.user.preferences.organizations.sort };
          setSortColumns(mysortedColumns);
        }
      } else {
        setGroups(['parent.name']);
      }
      // set state when the data received
      setData(mydata);
      setLoading(false);
    };
    dataFetch();
  }, [reload]);
  const groupOptions = {
    enableLazyLoading: true,
    columns: groups,
  };
  const clickHandler = (args) => {
    const selectedRecords = grid.getSelectedRecords();
    if (grid && args.item.properties.text === 'Recordings') {
      navigate('../recordings');
    } else if (grid && args.item.properties.text === 'OTA') {
      navigate('../ota');
    } else if (grid && args.item.properties.text === 'Jobs') {
      if (!selectedRecords || selectedRecords.length === 0) {
        alert('Please select an Organization');
      } else {
        navigate(`../jobs?id=${selectedRecords[0]._id}&name=${selectedRecords[0].title}`);
      }
    }
  };
  const actionComplete = (args) => {
    const editedData = args.data;
    const activeOrg = JSON.parse(localStorage.getItem('organization'));
    const cols = grid.columns;
    for (const col of cols) {
      if (col.headerText === 'Image') {
        col.allowEditing = false;
      }
    }
    /** Set initial Focus */
    if (args.requestType === 'beginEdit') {
      args.form.elements.namedItem('title').focus();
      // eslint-disable-next-line prefer-destructuring
      const dialog = args.dialog;
      dialog.header = `Details of ${args.rowData.title}`;
    } else if (args.form && args.requestType === 'add') {
      args.form.elements.namedItem('title').focus();
    } else if (args.requestType === 'save' && args.form && args.action === 'add') {
      if (args.data) {
        // The default edit operation is cancelled
        if (!editedData.parent && activeOrg) {
          editedData.parent._id = activeOrg.id;
          editedData.parent.name = activeOrg.name;
        } else {
          orgData.forEach((element) => {
            if (element.value == editedData.parent.name) {
              editedData.parent._id = element.id;
              editedData.parent.name = element.value;
            }
          });
        }
        // Here you can send the updated data to your server using AJAX call
        fetch(`${REACT_APP_API_ENDPOINT}/organizations`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(editedData),
        })
          .then((response) => response.json())
          .then((responseJson) => {
            // The added/edited data will be saved in the Grid
            if (responseJson && responseJson.response && responseJson.response !== 'OK') {
              alert(responseJson.response);
              args.cancel = true;
            }
            if (responseJson && responseJson._id) {
              args.data._id = responseJson._id;
            }
            setReload(!reload);
          })
          .catch((error) => {
            args.cancel = true;
          });
      }
    } else if (args.requestType === 'save' && args.form && args.action === 'edit') {
      if (args.data) {
        let setParent = false;
        orgData.forEach((element) => {
          if (element.value == editedData.parent.name) {
            editedData.parent._id = element.id;
            editedData.parent.name = element.value;
            setParent = true;
          }
        });
        if (activeOrg && !setParent) {
          editedData.parent._id = activeOrg.id;
          editedData.parent.name = activeOrg.name;
        }
        // The default edit operation is cancelled
        args.cancel = true;
        // Here you can send the updated data to your server using AJAX call
        fetch(`${REACT_APP_API_ENDPOINT}/organizations`, {
          method: 'UPDATE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(editedData),
        })
          .then((response) => response.json())
          .then((responseJson) => {
            // The added/edited data will be saved in the Grid
            // window.location.reload();
            if (responseJson && responseJson.response && responseJson.response !== 'OK') {
              alert(responseJson.response);
              args.cancel = true;
            }
            setReload(!reload);
          })
          .catch((error) => {
            args.cancel = true;
          });
      }
    } else if (args.requestType === 'delete') {
      let entry = ' this entry?';
      if (args.data.length > 1) {
        entry = ' these entries?';
      }
      if (args.data) {
        // The default edit operation is cancelled
        args.cancel = true;
        // Here you can send the updated data to your server using AJAX call
        fetch(`${REACT_APP_API_ENDPOINT}/organizations`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(editedData),
        })
          .then((response) => response.json())
          .then((responseJson) => {
            // The added/edited data will be saved in the Grid
            if (responseJson && responseJson.response && responseJson.response !== 'OK') {
              alert(responseJson.response);
              args.cancel = true;
            }
            setReload(!reload);
          })
          .catch((error) => {
            args.cancel = true;
          });
      }
    }
  };
  function setUserPreferences(pref, val) {
    if (!data.user.preferences) {
      data.user.preferences = {};
    }
    if (!data.user.preferences.organizations) {
      data.user.preferences.organizations = {};
    }
    if (pref === 'group') {
      data.user.preferences.organizations = { group: val, sort: data.user.preferences.organizations.sort };
    } else if (pref === 'sort') {
      data.user.preferences.organizations = { group: data.user.preferences.organizations.group, sort: val };
    }
    // Remove the New Password to avoid creating a new password
    data.user.newPassword = '';
    // Avoid Password comparison
    data.user.password = '';
    fetch(`${REACT_APP_API_ENDPOINT}/user`, {
      method: 'UPDATE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWTToken ${usr.token}`,
      },
      body: JSON.stringify(data.user),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson && responseJson.response && responseJson.response !== 'OK') {
          alert(responseJson.response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const actionBegin = (args) => {
    if (args.requestType === 'ungrouping' || args.requestType === 'grouping') {
      const index = groups.indexOf(args.columnName);
      if (index > -1 && args.requestType === 'ungrouping') { // only splice array when item is found
        groups.splice(index, 1); // 2nd parameter means remove one item only
      } else if (index === -1 && args.requestType === 'grouping') { // only splice array when item is found
        groups.push(args.columnName); // 2nd parameter means remove one item only
      }
      setUserPreferences('group', groups);
    } else if (args.requestType === 'sorting') {
      setUserPreferences('sort', [{ field: args.columnName, direction: args.direction }]);
    }
    if (grid) {
      const cols = grid.columns;
      for (const col of cols) {
        if (col.headerText === 'Image') {
          col.allowEditing = false;
        }
        if (args.requestType == 'beginEdit') {
          if (col.headerText == 'Address' || col.headerText == 'City' || col.headerText == 'State' || col.headerText == 'Zip') {
            col.visible = true;
            args.rowData.organization = args.rowData.parent.name;
          }
          if (col.headerText == 'Parent') {
            col.visible = false;
            col.allowEditing = true;
          }
          if (col.headerText == 'Organization') {
            col.visible = false;
          }
        }
        if (args.requestType == 'add') {
          if (col.headerText == 'Address' || col.headerText == 'City' || col.headerText == 'State' || col.headerText == 'Zip') {
            col.visible = true;
          }
          if (col.headerText == 'Organization') {
            col.visible = false;
          }
          if (col.headerText == 'Parent') {
            col.visible = true;
          }
        } else {
          if (col.headerText == 'Organization' || col.headerText == 'Address' || col.headerText == 'City' || col.headerText == 'State' || col.headerText == 'Zip') {
            col.visible = true;
          }
          if (col.headerText == 'Organization') {
            col.visible = false;
          }
          if (col.headerText == 'Parent') {
            col.visible = true;
          }
        }
      }
    }
  };
  if (loading) {
    return <p>Loading... </p>;
  }
  let crudUpdate = false;
  let crudCreate = false;
  let crudDelete = false;
  let crudRecordingCreate = false;
  if (data.role.organizations.permissions.includes('Create')) {
    crudCreate = true;
  }
  if (data.role.organizations.permissions.includes('Update')) {
    crudUpdate = true;
  }
  if (data.role.organizations.permissions.includes('Delete')) {
    crudDelete = true;
  }
  if (data.role.roles.permissions.includes('Read')) {
    crudRecordingCreate = true;
  }
  if (!data.role.organizations.permissions.includes('Read')) {
    return <p>Not authorized...</p>;
  }

  const recordings = { text: 'Recordings', tooltipText: 'Canned Recordings', prefixIcon: 'e-expand', id: 'recordings', disabled: !crudRecordingCreate };
  const otas = { text: 'OTA', tooltipText: 'OTA bin files', prefixIcon: 'e-copy', id: 'ota', disabled: !crudRecordingCreate };
  const jobs = {
    text: 'Jobs', tooltipText: 'Jobs', prefixIcon: 'e-table-insert-column', id: 'jobs', disabled: enableJobs,
  };
  const toolbarOptions = ['Search', 'Add', 'Edit', 'Delete', recordings, otas, jobs];

  const editing = {
    allowEditing: crudUpdate,
    allowAdding: crudCreate,
    allowDeleting: crudDelete,
    showConfirmDialog: true,
    showDeleteConfirmDialog: true,
    mode: 'Dialog',
  };
  function onFileUpload(args) {
    // add addition data as key-value pair.
    args.customFormData = [{ organizationid: data.user.organizationid }];
  }
  function completeUpload(args) {
    alert('Upload complete');
  }
  let uploadObj;
  const path = {
    removeUrl: `${REACT_APP_API_ENDPOINT}/organizationsremove`,
    saveUrl: `${REACT_APP_API_ENDPOINT}/organizationsupload`,
  };
  const buttons = { browse: 'Choose File', clear: 'Clear All', upload: 'Upload All' };

  const FilterOptions = {
    type: 'FilterBar',
    showFilterBarStatus: true,
    mode: 'Immediate',
  };
  data.organization.forEach((org) => {
    orgData.push({ id: org._id, value: org.title });
  });
  data.organization.orgData = orgData;
  const orgParams = {
    params: {
      actionComplete: () => false,
      allowFiltering: true,
      dataSource: orgData,
      fields: { value: 'value', id: 'id' },
      query: new Query(),
    },
  };
  const currentThemeColor = localStorage.getItem('colorMode');
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl zackat-div">
      <Header category="" title="Organizations" />
      <div style={{
        backgroundColor: currentThemeColor,
        textAlign: 'right',
        marginTop: '-40px',
      }}
      >

        <div id="droparea">{crudCreate ? 'IMPORT' : ''}
          {crudCreate === true && (
            <UploaderComponent
              id="fileupload"
              type="file"
              ref={(uplaod) => { uploadObj = uplaod; }}
              asyncSettings={path}
              buttons={buttons}
              multiple={false}
              actionComplete={completeUpload}
              autoUpload={false}
              uploading={onFileUpload}
              allowedExtensions=".csv"
            />
          )}
        </div>
      </div>
      <GridComponent
        id="gridcomp"
        dataSource={data.organization}
        allowPaging
        allowSorting
        contextMenuItems={contextMenuItems}
        editSettings={editing}
        toolbar={toolbarOptions}
        toolbarClick={clickHandler}
        allowResizing
        allowGrouping
        groupSettings={groupOptions}
        sortSettings={sortOptions}
        allowFiltering={data && data.organization && data.organization.length > 0}
        filterSettings={FilterOptions}
        actionComplete={actionComplete}
        actionBegin={actionBegin}
        recordClick={rowClicked}
        allowTextWrap
        ref={(g) => { grid = g; }}
      >
        <ColumnsDirective>
          <ColumnDirective field="title" headerText="Customer Name" width="150" validationRules={valuerules} textAlign="Left" />
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {organizationsGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
          <ColumnDirective
            field="parent._id"
            headerText="Organization"
            width="150"
            visible={false}
            editType="dropdownedit"
            validationRules={valuerules}
            foreignKeyField="id"
            foreignKeyValue="value"
            dataSource={orgParams.params.dataSource}
          />
        </ColumnsDirective>
        <Inject services={[Search, Page, Edit, Toolbar, Filter, Sort, ContextMenu, Resize, ForeignKey, Group, LazyLoadGroup]} />
      </GridComponent>

    </div>
  );
};
export default Organizations;
