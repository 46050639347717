/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import {
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
  Day,
  Week,
  WorkWeek,
  Month,
  Agenda,
  Inject,
  Resize,
  DragAndDrop,
} from '@syncfusion/ej2-react-schedule';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';

import { Header } from '../components';
import './Zackat.css';

// eslint-disable-next-line react/destructuring-assignment
const PropertyPane = (props) => <div className="mt-5">{props.children}</div>;

const Scheduler = () => {
  const [scheduleObj, setScheduleObj] = useState();
  const { REACT_APP_API_ENDPOINT } = process.env;

  const change = (args) => {
    scheduleObj.selectedDate = args.value;
    scheduleObj.dataBind();
  };

  const onDragStart = (arg) => {
    // eslint-disable-next-line no-param-reassign
    arg.navigation.enable = true;
  };
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const usr = JSON.parse(localStorage.getItem('user'));
  const selectedOrg = JSON.parse(localStorage.getItem('organization'));
  useEffect(() => {
    // limit associates to the overall selection
    if (selectedOrg && selectedOrg.id && selectedOrg.id.length > 0) {
      usr.organizationid = selectedOrg.id;
    }
    // fetch data
    const dataFetch = async () => {
      const query = Object.entries(usr)
        .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
        .join('&');
      const url = `${REACT_APP_API_ENDPOINT}/calendar_events?${query}`;
      console.log(url);
      const mydata = await (
        await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
        })
      ).json();

      // set state when the data received
      setData(mydata);
      setLoading(false);
    };
    dataFetch();
  }, []);
  if (loading) {
    return <p>Loading... </p>;
  }
  const onRendercell = (args) => {
    (args.element).style.background = args.data.CategoryColor;
  };

  const actionComplete = (args) => {
    let editedData;
    if ((args.requestType === 'beginEdit' || args.requestType === 'add')) {
      /** Add Validation Rules */
    }
    /** Set initial Focus */
    if (args.requestType === 'eventCreated' && args.addedRecords.length > 0) {
      editedData = args.addedRecords;
      editedData[0].organizationid = selectedOrg.id;
      // The default edit operation is cancelled
      // Here you can send the updated data to your server using AJAX call
      fetch(`${REACT_APP_API_ENDPOINT}/calendar_events`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWTToken ${usr.token}`,
        },
        body: JSON.stringify(editedData),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson && responseJson._id) {
            for (let i = 0; i < args.addedRecords.length; i += 1) {
              if (responseJson[i] && responseJson[i]._id) {
                args.addedRecords[i]._id = responseJson[i]._id;
              }
            }
          }
          // window.location.reload();
          // The added/edited data will be saved in the Grid
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (args.requestType === 'eventChanged' && args.changedRecords.length > 0) {
      editedData = args.changedRecords;
      // The default edit operation is cancelled
      // Here you can send the updated data to your server using AJAX call
      editedData[0].organizationid = selectedOrg.id;
      fetch(`${REACT_APP_API_ENDPOINT}/calendar_events`, {
        method: 'UPDATE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWTToken ${usr.token}`,
        },
        body: JSON.stringify(editedData),

      })
        .then((response) => response.json())
        .then((responseJson) => {
          // The added/edited data will be saved in the Grid
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (args.requestType === 'eventRemoved' && args.deletedRecords.length > 0) {
      editedData = args.deletedRecords;
      // The default edit operation is cancelled
      // Here you can send the updated data to your server using AJAX call
      fetch(`${REACT_APP_API_ENDPOINT}/calendar_events`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWTToken ${usr.token}`,
        },
        body: JSON.stringify(editedData),

      })
        .then((response) => response.json())
        .then((responseJson) => {
          // The added/edited data will be saved in the Grid
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  let crudUpdate = false;
  let crudCreate = false;
  let crudDelete = false;
  if (data.role.calendar.permissions.includes('Create')) {
    crudCreate = true;
  }
  if (data.role.calendar.permissions.includes('Update')) {
    crudUpdate = true;
  }
  if (data.role.calendar.permissions.includes('Delete')) {
    crudDelete = true;
  }
  let crudReadOnly = false;
  if (!crudCreate && !crudDelete && !crudUpdate) {
    crudReadOnly = true;
  }
  if (!data.role.calendar.permissions.includes('Read')) {
    return <p>Not authorized...</p>;
  }
  function onPopUp(args) {
    if (args.type === 'QuickInfo') {
      const dialogObj = args.element.ej2_instances[0];
      if (crudUpdate === false) {
        dialogObj.hide();
      }
      //      var currentAction = args.target.classList.contains('e-work-cells') ? 'Add' : 'Save'
      //      scheduleObj.openEditor(args.data, currentAction)
    }
  }

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl zackat-div">
      <Header category="App" title="Calendar" />
      <ScheduleComponent
        height="650px"
        ref={(schedule) => setScheduleObj(schedule)}
        selectedDate={new Date()}
        eventSettings={{ dataSource: data.calendar }}
        eventRendered={onRendercell}
        readOnly={crudReadOnly}
        popupOpen={() => onPopUp}
        dragStart={onDragStart}
        actionComplete={actionComplete}
      >
        <ViewsDirective>
          {['Day', 'Week', 'WorkWeek', 'Month', 'Agenda'].map((item) => (
            <ViewDirective
              key={item}
              option={item}
            />
          ))}
        </ViewsDirective>
        <Inject services={[Day, Week, WorkWeek, Month, Agenda, Resize, DragAndDrop]} />
      </ScheduleComponent>
      <PropertyPane>
        <table
          style={{ width: '100%', background: 'white' }}
        >
          <tbody>
            <tr style={{ height: '50px' }}>
              <td style={{ width: '100%' }}>
                <DatePickerComponent
                  value={new Date()}
                  showClearButton={false}
                  placeholder="Current Date"
                  floatLabelType="Always"
                  change={change}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </PropertyPane>
    </div>
  );
};

export default Scheduler;
